/*Extra small devices (portrait phones, less than 320px)*/
@media  (max-width: 320px){
    .bottom-navbar{
        display: block;
    }

    .search-bar form input {
      padding-right: 25px !important;
      font-size: 12px !important;
  }
  .big-search .search-bar form input {
    width: 100% !important;
    font-size: 12px;
    font-weight: 600;
}
  .search-bar form button img {
      max-width: 16px !important;
  }
  .search-bar form {
      width: 70% !important;
  }
  .big-search .search-bar form {
    width: 95% !important;
}

}
/*Extra small devices (portrait phones, less than 360px)*/
@media  (max-width: 360px){








}
/*Extra small devices (portrait phones, less than 375px)*/
@media  (max-width: 375px){






}
/*Extra small devices (portrait phones, less than 414px)*/
@media  (max-width: 414px){







}
/*Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
    .bottom-navbar{
        display: block;
    }
    header .container-fluid {
        padding: 0 15px;
    }
    .wrapper .left-item {
        position: relative;
        position: static;
        top: 0;
        left: 0;
        max-width: 100%;
        width: 100%;
        height: auto;
        transition: all 0.3s ease;
        max-height: 100%;
        background: #13161c;
        padding: 20px 20px 20px 20px;

    }
    .wrapper .left-item .item2 a {
        margin-right: 5px;
    }
    
    .search-bar a {
        margin-left: 5px;
        font-size: 12px;
    }
    .search-bar form {
        position: relative;
        width: 72%;
    }
    .big-search .search-bar form {
        width: 95% !important;
    }
    .search-bar form button {
        top: 45%;
    }
    .search-bar ul li {
        margin-right: 16px !important;
    }
    .search-bar .navbar-nav {
        margin-top: 14px;
    }
    .search-bar form button {
        left: auto;
        border: 0;
        right: 0;
    }
    .search-bar form input {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 10px;
        border: none;
        width: 100%;
        padding-left: 15px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 35px;
        font-size: 14px;
    }
    .big-search .search-bar form input {
        width: 320px;
        font-size: 14px;
        font-weight: 600;
    }
    .search-bar form button img {
        max-width: 18px;
    }
    .search-bar {
        position: relative;
        margin-bottom: 25px;
        margin-top: 20px;
    }
    .sidenav {  padding-top: 20px;
    }
    .sidenav {
        height: 100%;
        width: 0;
        position: fixed;
        z-index: 99999;
        top: 0;
        left: 0;
        background: #13161c;
        overflow-x: hidden;
        transition: 0.5s;
        padding-top: 60px;
    }
    .sidenav button {
        padding: 8px 8px 8px 32px;
        text-decoration: none;
        font-size: 25px;
        color: #818181;
        display: block;
        transition: 0.3s;
        background: transparent;
        border: 0;
    }
    .sidenav button:hover {
        color: #f1f1f1;
    }
    .sidenav .closebtn {
        position: absolute;
        top: 5px;
        right: 15px;
        font-size: 35px;
        margin-left: 50px;
        line-height: 100%;
        background: #13161c;
        padding: 5px;
        z-index: 9;
    }
    .sidenav {padding-top: 15px;}
    .wrapper {
        padding-left: 0;
    }
    .mide-item {
        padding-bottom: 80px;
    }
    .mide-item .top-songs .slider .ex-a .title-item .singer-name {
        font-size: 12px;
        line-height: 18px;
    }
    .mide-item .top-songs .slider .ex-a .title-item .songstitle {
        font-size: 12px;
        line-height: 20px;
    }
    .mobile-bottom-menu ul li.button3,.mobile-bottom-menu ul li.button2 a {
      margin-left: 10px;

  }
  .mobile-bottom-menu ul li.button1 a {
      margin-left: 8px;
  }
  .mobile-bottom-menu ul li {
      margin-right: 0;
  }

  .mobile-bottom-menu ul li.button1 a, .mobile-bottom-menu ul li.button2 a {
      font-size: 14px;
      padding: 8px 30px !important;
  }

  .mobile-bottom-menu ul li.button3 a {
      background: #180A21;
  }
  .home-banner {
      padding: 25px;
  }
  .home-banner h1 {
      font-size: 16px;
      line-height: 145%;
  }
  .Contribution .item-left .top-item .media {
      padding: 15px;
  }
  .Contribution .item-left .top-item .media-body .box-inner .media {
      border-radius: 12px;
      padding: 10px 10px;
  }
  .Contribution .item-left .top-item .media-body p {
      font-size: 14px;
      line-height: 120%;
  }
  .Contribution .item-left .top-item .media-body h4 {
      font-size: 12px;
  }
  .Contribution .item-left .top-item .media-body h4 span {
      font-size: 10px;
  }

  .item-table table thead tr th {
    padding: 12px 10px;
    font-size: 10px;
}
.item-table table tbody tr th span {
    width: 22px;
    height: 22px;
    font-size: 12px;
    padding-top: 1px;
}
.item-table table tbody tr td {
    padding: 12px 10px;
    font-size: 10px;
    text-align: center;
}
.item-table table tbody tr td img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 0;
    display: block;
    margin: auto;
    margin-bottom: 5px;
}

.item-table .pagination li:first-child a,.item-table .pagination li:last-child a {
    padding: 6px 10px;
    font-size: 10px;
    line-height: 15px;
}
.item-table .pagination li a {
    background: transparent;
    border-radius: 8px;
    padding: 6px 10px;
    font-weight: 600;
    font-size: 10px;
    line-height: 15px;
}
.Notifications::before{
    display: none;
}
.nav-drop .dropdown-menu, .Notifications-body {
    min-width: auto;
    max-width: 100%;
}

.profile-item .media-body h2 {
    font-size: 22px;
    line-height: 100%;
    margin-bottom: 10px;
}
.profile-item .media-body h2 img {
    width: 20px;
    height: 20px;
}
.profile-item .media-body p {
    font-size: 12px;
    line-height: 140%;
    margin-bottom: 10px;
}
.profile-item .media-body .button-item ul li button {
    padding: 6px 12px;
    font-size: 12px;
    line-height: 150%;
}
.profile-item .media-body .button-item ul li a {
    font-size: 10px;
}
.profile-item .media .profile-images {
    margin-right: 15px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
}
.mobile-bottom-menu ul li .dot-cuarcale {
    top: 14px;
    right: 12px;
}
.profile-item .media-body .button-item ul li:first-child {
    margin-right: 6px;
}
.Login .box form .form-check label {
    margin-bottom: 0;
    position: relative;
    top: 0;
    display: inline;
    font-size: 14px;
}
.Edit-Profile .Avatar .media img {
    width: 75px;
    height: 75px;
    margin-right: 20px;
}
.Edit-Profile .Avatar .media .media-body ul li a,
.btn-profile {
    padding: 8px 18px;
    font-size: 14px;
    line-height: 140%;
}

.Edit-Profile .Edit-top h2 {
    font-size: 16px;
}
.Edit-Profile .Edit-top h2 a img {
    margin-right: 15px;
    width: 25px;
}
.Login .box form input, .Login .box form select {
    padding: 10px 20px;
    font-size: 14px;
}
.Login .box form label {
    font-size: 14px;
}
.top-songs-body .search-bar form input {
    padding: 8px 12px;
    padding-right: 20px;
    font-size: 14px;
    max-width: 100%;
    width: 140px;
}
.top-songs-body .search-bar form button img {
    max-width: 14px;
}
.top-songs-body .Played table thead tr th {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 12px;
}


.top-songs-body .Played table tbody tr td, .top-songs-body .Played table thead tr th {
    text-align: center;
}
.Played table tbody tr td:nth-child(2) img:nth-child(2) {
    width: 13px;
    margin-left: -3px;
}
.Played table tbody tr td:nth-child(2) img {
    width: 40px;
}
.Played table tbody tr td:nth-child(2) span.chip {
    margin-left: 10px;
    font-size: 12px;
}
.Played table thead tr th:last-child {
    width: 232px;
    min-width: 230px;
}

.Played table thead tr th:first-child {
    min-width: 60px;
}

.Played table thead tr th:nth-child(2) {
    min-width: 220px;
}
.Played table thead tr th:nth-child(3) {
    min-width: 140px;
}
.Played table thead tr th:nth-child(4) {
    min-width: 220px;
}
.mide-item .profile-body .search-bar {
    position: relative;
    top: 0;
    margin: 0;
}
/* .Played table tbody tr td, .Played table thead tr th {
    font-size: 12px;
} */
.profile-item .media {
    display: block;
    text-align: center;
}
.profile-item .media .box-img img {
    margin-right: 0;
    margin-bottom: 0px;
}
.profile-item .media .box-img {
    position: relative;
    margin-right: 0;
    margin-bottom: 10px;
}
.profile-item .media .box-img span {
    padding: 8px 5px;
    font-weight: 600;
    font-size: 12px;
    right: -60px;
    bottom: 2px;
    margin: auto;
    left: 0;
}
.profile-item .media .left-side ul li {
    margin-right: 0;
    margin-bottom: 5px;
}
.Blinding-Lights .Blinding-right .media a.user img {
    max-width: 100%;
    display: block;
    width: 100%;
    margin-bottom: 20px;
}

.Blinding-Lights .Blinding-right .media a.user {
    display: block;
    margin: 0;
}
.Video-Tutorial .video-tutorial-left .nav_body ul li .formFile label {
    font-size: 14px;
    position: static;
    right: 0;
    top: 0;
}
.Video-Tutorial .video-tutorial-left .nav_body ul li {
    margin-right: 0;
    margin-bottom: 20px;
}
.Video-Tutorial .lock-right-item .Blinding-Lights .Blinding-right .media a.user img {
    width: 100%;
    max-width: 100%;
}
.Light-Theme .sidenav {
    background: #f4f4f6;
}

.Light-Theme .search-bar form input {
    background: #F4F4F6;
    border: 1px solid rgb(24 21 21 / 25%);
}

.Light-Theme .search-bar ul .nav-item .nav-link {
    background: #ffffff;
    color: #180A21;
}

.mide-item .nav_body ul li:last-child {
    margin-right: 0;
    width: auto;
    margin-left: 0;
}























}
/*// Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) and (max-width: 767.98px) {
    .bottom-navbar{
        display: block;
    }
    header .container-fluid {
        padding: 0 15px;
    }
    .wrapper .left-item {
        position: relative;
        position: static;
        top: 0;
        left: 0;
        max-width: 100%;
        width: 100%;
        height: auto;
        transition: all 0.3s ease;
        max-height: 100%;
        background: #13161c;
        padding: 20px 20px 20px 20px;

    }
    .wrapper .left-item .item2 a {
        margin-right: 5px;
    }
    
    .search-bar a {
        margin-left: 5px;
        font-size: 12px;
    }
    .search-bar form {
        position: relative;
        width: 75%;
    }
    .big-search .search-bar form {
        width: 95% !important;
    }
    .search-bar form button {
        left: auto;
        border: 0;
        right: 0;
    }
    .search-bar form input {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 10px;
        border: none;
        width: 100%;
        padding-left: 15px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 35px;
        font-size: 14px;
    }
    .big-search .search-bar form input {
        width: 576px;
        font-size: 14px;
        font-weight: 600;
    }
    .search-bar form button img {
        max-width: 18px;
    }
    .search-bar {
        position: relative;
        margin-bottom: 25px;
        margin-top: 20px;
    }
    .sidenav {  padding-top: 20px;
    }
    .sidenav {
        height: 100%;
        width: 0;
        position: fixed;
        z-index: 99999;
        top: 0;
        left: 0;
        background: #13161c;
        overflow-x: hidden;
        transition: 0.5s;
        padding-top: 60px;
    }
    .sidenav button {
        padding: 8px 8px 8px 32px;
        text-decoration: none;
        font-size: 25px;
        color: #818181;
        display: block;
        transition: 0.3s;
        background: transparent;
        border: 0;
    }
    .sidenav button:hover {
        color: #f1f1f1;
    }
    .sidenav .closebtn {
        position: absolute;
        top: 5px;
        right: 15px;
        font-size: 35px;
        margin-left: 50px;
        line-height: 100%;
        background: #13161c;
        padding: 5px;
        z-index: 9;
    }
    .wrapper {
        padding-left: 0;
    }
    .mide-item {
        padding-bottom: 80px;
    }
    .mide-item .mide-banner {
        padding: 40px 40px;
    }
    .search-bar ul li {
        margin-right: 16px !important;
    }
    .search-bar .navbar-nav {
        margin-top: 14px;
    }

    .mobile-bottom-menu ul li.button1 a {
      margin-left: 10px;
  }
  .mobile-bottom-menu ul li {
      margin-right: 0;
  }

  .mobile-bottom-menu ul li.button1 a, .mobile-bottom-menu ul li.button2 a {
      font-size: 16px;
      padding: 8px 30px !important;
  }

  .mobile-bottom-menu ul li.button3 a {
      background: #180A21;
  }
  .item-table table tbody tr td, .item-table table tbody tr th {
    padding: 12px 20px;
    font-size: 14px;
}

.Notifications::before{
    display: none;
}
.nav-drop .dropdown-menu, .Notifications-body {
    min-width: auto;
    max-width: 100%;
}
.profile-item .media-body h2 {
    font-size: 30px;
    line-height: 100%;
    margin-bottom: 10px;
}
.profile-item .media-body p {
    font-size: 16px;
    line-height: 140%;
    margin-bottom: 20px;
}
.profile-item .media .profile-images {
    margin-right: 30px;
    width: 150px;
    height: 150px;
    border-radius: 50%;
}

.mobile-bottom-menu ul li .dot-cuarcale {
    top: 14px;
    right: 12px;
}

.top-songs-body .search-bar form input {
    padding: 8px 12px;
    padding-right: 20px;
    font-size: 14px;
    max-width: 100%;
    width: 140px;
}
.top-songs-body .search-bar form button img {
    max-width: 14px;
}
.top-songs-body .Played table thead tr th {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 12px;
}


.top-songs-body .Played table tbody tr td, .top-songs-body .Played table thead tr th {
    text-align: center;
}
.Played table tbody tr td:nth-child(2) img:nth-child(2) {
    width: 13px;
    margin-left: -3px;
}
.Played table tbody tr td:nth-child(2) img {
    width: 40px;
}
.Played table tbody tr td:nth-child(2) span.chip {
    margin-left: 10px;
}
.Played table thead tr th:last-child {
    width: 232px;
    min-width: 230px;
}

.Played table thead tr th:first-child {
    min-width: 60px;
}

.Played table thead tr th:nth-child(2) {
    min-width: 220px;
}
.Played table thead tr th:nth-child(3) {
    min-width: 140px;
}
.Played table thead tr th:nth-child(4) {
    min-width: 220px;
}

.mide-item .profile-body .search-bar {
    position: relative;
    top: 0;
    margin: 0;
}
.mobile-bottom-menu ul li .dot-cuarcale {
    top: 14px;
    right: 12px;
}
/* .Played table tbody tr td, .Played table thead tr th {
    font-size: 12px !important;
} */
.Video-Tutorial .video-tutorial-left .nav_body ul li .formFile label {
    font-size: 14px;
    position: static;
    right: 0;
    top: 0;
}
.Video-Tutorial .video-tutorial-left .nav_body ul li {
    margin-right: 0;
    margin-bottom: 20px;
}
.Video-Tutorial .lock-right-item .Blinding-Lights .Blinding-right .media a.user img {
    width: 100%;
    max-width: 100%;
}
.Light-Theme .sidenav {
    background: #f4f4f6;
}
.Light-Theme .search-bar form input {
    background: #F4F4F6;
    border: 1px solid rgb(24 21 21 / 25%);
}
.Light-Theme .search-bar ul .nav-item .nav-link {
    background: #ffffff;
    color: #180A21;
}

.mide-item .nav_body ul li:last-child {
    margin-right: 0;
    width: auto;
    margin-left: 0;
}
}

/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) and (max-width: 991.98px) {
    .bottom-navbar{
        display: block;
    }
    header .container-fluid {
        padding: 0 15px;
    }
    .wrapper .left-item {
        position: relative;
        position: static;
        top: 0;
        left: 0;
        max-width: 100%;
        width: 100%;
        height: auto;
        transition: all 0.3s ease;
        max-height: 100%;
        background: #13161c;
        padding: 20px 20px 20px 20px;

    }
    .wrapper .left-item .item2 a {
        margin-right: 5px;
    }
    
    .search-bar a {
        margin-left: 5px;
        font-size: 12px;
    }
    .search-bar form {
        position: relative;
        width: 75%;
    }
    .big-search .search-bar form {
        width: 95% !important;
    }
    .search-bar form button {
        left: auto;
        border: 0;
        right: 0;
    }
    .search-bar form input {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 10px;
        border: none;
        width: 100%;
        padding-left: 15px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 35px;
        font-size: 14px;
    }
    .search-bar form button img {
        max-width: 18px;
    }
    .search-bar {
        position: relative;
        margin-bottom: 25px;
        margin-top: 20px;
    }
    .sidenav {  padding-top: 20px;
    }
    .sidenav {
        height: 100%;
        width: 0;
        position: fixed;
        z-index: 99999;
        top: 0;
        left: 0;
        background: #13161c;
        overflow-x: hidden;
        transition: 0.5s;
        padding-top: 60px;
    }
    .sidenav button {
        padding: 8px 8px 8px 32px;
        text-decoration: none;
        font-size: 25px;
        color: #818181;
        display: block;
        transition: 0.3s;
        background: transparent;
        border: 0;
    }
    .sidenav button:hover {
        color: #f1f1f1;
    }
    .sidenav .closebtn {
        position: absolute;
        top: 5px;
        right: 15px;
        font-size: 35px;
        margin-left: 50px;
        line-height: 100%;
        background: #13161c;
        padding: 5px;
        z-index: 9;
    }
    .wrapper {
        padding-left: 0;
    }
    .mide-item {
        padding-bottom: 80px;
    }
    .mide-item .mide-banner {
        padding: 40px 40px;
    }
    .search-bar ul li {
        margin-right: 16px !important;
    }
    .search-bar .navbar-nav {
        margin-top: 14px;
    }

    .mobile-bottom-menu ul li.button1 a {
      margin-left: 10px;
  }
  .mobile-bottom-menu ul li {
      margin-right: 0;
  }

  .mobile-bottom-menu ul li.button1 a, .mobile-bottom-menu ul li.button2 a {
      font-size: 14px;
      padding: 8px 30px !important;
  }

  .mobile-bottom-menu ul li.button3 a {
      background: #180A21;
  }
  .Notifications::before{
    display: none;
}
.nav-drop .dropdown-menu, .Notifications-body {
    min-width: auto;
    max-width: 100%;
}

.top-songs-body .search-bar form input {
    padding: 8px 12px;
    padding-right: 20px;
    font-size: 14px;
    max-width: 100%;
    width: 140px;
}
.top-songs-body .search-bar form button img {
    max-width: 14px;
}
.top-songs-body .Played table thead tr th {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 12px;
}


.top-songs-body .Played table tbody tr td, .top-songs-body .Played table thead tr th {
    text-align: center;
}
.Played table tbody tr td:nth-child(2) img:nth-child(2) {
    width: 13px;
    margin-left: -3px;
}
.Played table tbody tr td:nth-child(2) img {
    width: 40px;
}
.Played table tbody tr td:nth-child(2) span.chip {
    margin-left: 10px;
}
.Played table thead tr th:last-child {
    width: 232px;
    min-width: 230px;
}

.Played table thead tr th:first-child {
    min-width: 60px;
}

.Played table thead tr th:nth-child(2) {
    min-width: 220px;
}
.Played table thead tr th:nth-child(3) {
    min-width: 140px;
}
.Played table thead tr th:nth-child(4) {
    min-width: 220px;
}
.mide-item .profile-body .search-bar {
    position: relative;
    top: 0;
    margin: 0;
}

.mobile-bottom-menu ul li .dot-cuarcale {
    top: 14px;
    right: 12px;
}

.Video-Tutorial .lock-right-item .Blinding-Lights .Blinding-right .media a.user img {
    width: 100%;
    max-width: 100%;
}
.Light-Theme .sidenav {
    background: #f4f4f6;
}
.Light-Theme .search-bar form input {
    background: #F4F4F6;
    border: 1px solid rgb(24 21 21 / 25%);
}
.Light-Theme .search-bar ul .nav-item .nav-link {
    background: #ffffff;
    color: #180A21;
}





















}
/*// Large devices (desktops, 992px and up)*/
@media (min-width: 992px) and (max-width: 1199.98px) {
    .wrapper .left-item {
      padding: 30px 25px 30px 25px;
  }
  .home-banner h1 {
      font-size: 20px;
  }
  .search-bar form input {
      width: 200px;
      padding: 10px 15px;
      padding-right: 15px;
      padding-right: 40px;
      font-size: 12px;
  }
  header .collapse {
      padding-left: 20px;
  }
  .search-bar ul li:nth-child(2) {
      margin-right: 15px;
  }
  .search-bar ul li:first-child {
      margin-right: 20px;
  }
  .search-bar ul li {
      margin-right: 10px;
  }
  header .collapse .navbar-nav .nav-item.button2 .nav-link {
      font-size: 14px;
  }
  header .collapse .navbar-nav .nav-item.button1 .nav-link {
      font-size: 14px;
  }


  .Played table tbody tr td:nth-child(2) span.chip {
    margin-left: 10px;
    font-size: 12px;
}
/* .Played table tbody tr td, .Played table thead tr th {
    font-size: 12px;
} */
.mide-item .profile-body ul li {
    margin-right: 2.83%;
}
.mide-item .profile-body .search-bar form input {
    width: 156px;
    padding: 10px 15px;
    padding-right: 15px;
    padding-right: 20px;
    font-size: 10px;
}
.mide-item .profile-body .search-bar form button img {
    width: 13px;
}
.mide-item .nav_body ul li:last-child {
    margin-right: 0;
    width: auto;
    margin-left: 0;
}
.arrow-show-hide-toggoler {
    margin-right: 10px;
}
header .navbar-brand {
    margin-right: 0;
}
header .navbar-brand img {
    /*max-width: 130px;*/
}
.arrow-show-hide-toggoler button {
    width: 30px;
    height: 30px;
}
.arrow-show-hide-toggoler button svg {
    max-width: 24px;
}

.mide-item .profile-body.nav_body ul li:last-child {
    width: auto;
}
.mide-item .profile-body .search-bar {
    top: 0px;
}
}
/*// Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) and (max-width: 1439.98px) {
    .mide-item .profile-body ul li {
        margin-right: 60px;
    }
}
/*// Extra large devices (large desktops, 1440px and up)*/
@media (min-width: 1440px) and (max-width: 1500.98px){
    .mide-item .profile-body ul li {
        margin-right: 60px;
    }
}
/*// Extra large devices (large desktops, 1440px and up)*/
@media (min-width: 1501px) and (max-width: 1699px){
    .mide-item .profile-body ul li {
        margin-right: 60px;
    }
}

/*// Extra large devices (large desktops, 1440px and up)*/
@media (min-width: 1700px) and (max-width: 2099.98px){
    .mide-item .profile-body ul li {
        margin-right: 60px;
    }
}
/*// Extra large devices (large desktops, 1440px and up)*/
@media (min-width: 2100px){
    .mide-item .profile-body ul li {
        margin-right: 60px;
    }
}

@media (max-width: 992px) and (orientation: landscape) {
    .mide-item:has(.Video-Tutorial) {
        padding-bottom: 0;
    }
}