.blurredBackgroundImage {
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  height: 100%;
  z-index: -5;
  width: 100%;
  position: fixed;
  filter: blur(25px);
  -webkit-filter: blur(25px);
  -moz-filter: blur(25px);
  -o-filter: blur(25px);
  -ms-filter: blur(25px);
}