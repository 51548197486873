.main {
    min-height: 52vh;
}

.title {
    margin-bottom: 30px;
}

section.mainSection {
    //background: white;
    border: 1px solid transparent;
    padding: 20px 0px 20px 20px;
    margin-top: 18px;

    // a:hover {
    //     border-top: 1px solid #ffffff81;
    //     outline-color: none;
    // }
}
