.channelNavBar {
  position: fixed;
  bottom: 51.6px;
  z-index: 3;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: solid 1px var(--bg-secondary);
  border-bottom: solid 1px var(--bg-secondary);
  padding: 8px 8px;
  margin-left: -12px;
  background-color: #14141485;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  
  .channelInfoWrapper {
    display: flex;
    gap: 10px;
    align-items: center;
    font-weight: 500;
    
    .channelPFP {
      border-radius: 50%;
      width: 50px;
    }
    .channelInfo {
      display: flex;
      flex-direction: column;
      
      .channelName {
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .channelSubCount {
        font-size: 0.7rem;
        font-weight: 400;
        color: #aeaeae;
      }
    }
  }
  .scoreIcon {
    background-color: transparent;
    padding: 6px 9px 9px 9px;
    margin-left: 4px;
    border-radius: 50%;
    border: solid 1px var(--bg-between-gradient);
  }

  &.lightMode {
    background-color: rgba(255, 255, 255, 0.5);

    .channelSubCount {
      color: var(--bg-primary) !important;
    }
  }
}
