.header {
    text-align: center;
    margin-top: 5px;
    text-transform: capitalize;
}

.artistletter {
    margin-top: 12px;
    DIV {
        padding-bottom: 12px;
    }
    LABEL, A {
        display: block;
    }
}

.banner {
    position: relative; 

    padding-top: 70px;

    margin-bottom: 30px;

    .bannerImage {
        width: 100%;
        height: 100%;
    
        background-size: cover;
    
        background-position: 0% 20%;
    
        filter: contrast(.8);

        position: absolute;
        top: 0;
        z-index: 1;
    }

    .bannerInner {
        margin-bottom: 0;
        color: white;
        font-size: 42;
        position: relative;
        width: 100%;
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 40%);
        z-index: 2;

        padding: 30px 20px 20px;

        H3 {
            margin: 10px 0 0;
            color: white;
            font-size: 56px;

            padding-top: 20px;
        }

        P {
            margin: 0;
            padding: 5px 0 20px;
        }
    }

    A {
        color: white;
        &:hover {
            color: white;
            text-decoration: underline;
        }
    }
}

.artistName {
    margin-top: 3px;
}

.artistLocation {
    font-weight: 100;
    font-size: 14px;
    display: flex;
    align-items: center;
    white-space: nowrap;

    img {
        margin-right: 5px;
    }

    @media (max-width: 576px) {
        justify-content: center;
    }
}

.searchBar {
    text-align: center;
    margin: 15px 0 5px 0;
}

.songsContainer {
    margin: 50px -12px 0 -12px;
}

.noResults {
    display: flex;
    justify-content: center;
    margin: 30px 0;
    font-style: oblique;
}

.tabList {
    justify-content: center;
    margin-top: 10px;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
}

.filterButton {
    border-radius: 10px;
    flex-grow: 2;
}

.songList {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 10px;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;

    li {
        &:not(:last-child) {
            border-bottom: 1px solid #2b2b2b;
            padding-bottom: 10px;
        }

        a {
            display: flex;
            align-items: center;
            gap: 8px;
            font-size: 14px;

            span {
                padding: 0 7px;
            }

            .songListPosition {
                text-align: center;
                width: 20px;
            }
            
            img {
                width: 50px;
            }

            & > :last-child {
                margin-left: auto;
            }
        }
    }
}