:root {
    --bg-primary: #171a1f;
    --bg-secondary: #191d23;
    --bg-tertiary: #2a2d33;
    --bg-gradient: linear-gradient(160deg, #7C84EF, #9B46D7);
    --bg-between-gradient: #8C65E3;
    --text-primary: #eaecef;
}

html{
    scroll-behavior: smooth;
}
body{
    font-family: 'GeneralSans', sans-serif;
    font-weight: 400;
    position: relative;
    color: var(--text-primary);
    background: var(--bg-primary);
}
body.Light-Theme{
    font-family: 'GeneralSans', sans-serif;
    font-weight: 400;
    position: relative;
    color: var(--bg-primary);
    background: #FFFFFF;
}
h1,h2,h3,h4,h5,h6{
    font-weight: 600;
}
a{
    display: inline-block;
    transition: all ease .6s;
    text-decoration: none;
    color: var(--text-primary);
}
.Light-Theme a{
    display: inline-block;
    transition: all ease .6s;
    text-decoration: none;
    color: var(--bg-primary);
}
.Light-Theme a:hover{
    color: var(--bg-primary);
}
a:hover{
    text-decoration: underline;
    color: white;
}
small{
    display: inline-block;
}
ul{
    margin: 0;
    list-style: none;
    padding: 0;
}
/* svg path{
fill: red;
} */
/* .container{
max-width: 1300px;
} */
/* header start */
header {
    transition: background-color ease .6s;
    padding: 10px 0;
}
.Light-Theme header {
    /*background: #180A21;*/
    background: rgba(256, 256, 256, 0.7) !important;
    padding: 10px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    /*filter: alpha(opacity=20);*/
    backdrop-filter: blur(40px);
}
footer {
    padding: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.bottom-navbar {
    overflow: hidden;
    z-index: 99999;
    position: fixed;
    bottom: 0;
    width: 100%;
    display: none;
  }
  .Light-Theme .bottom-navbar{
    background-color: #F4F4F6
  }
  .bottom-navbar .nav-list{
    background: var(--bg-gradient);
    padding: 5px;
  }
  .container-flex{
    display: flex;
    justify-content: space-around;
  }
header .navbar{

}
header .container-fluid{}
header .navbar-brand{}
header .navbar-brand img{}
header .navbar-toggler{}
header .navbar-toggler svg{
    width: 20px;
}
header .dropdown-toggle::after{
    display: none;
}
header .navbar-toggler .navbar-toggler-icon{}
header .collapse {
    padding-left: 80px;
}
.search-bar{
    position: relative;
}
.search-bar form{
    position: relative;
}
.search-bar form input {
    border-radius: 2rem;
    border: none;
    width: 326px;
    padding: 10px 16px;
    padding-right: 40px;
    font-size: 14px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: rgba(255, 255, 255, 0.1);
    color: white;
}
.big-search .search-bar form input {
    width: 620px;
    height: 65px;
    font-size: 18px;
    font-weight: 700;
}
.Light-Theme .big-search .search-bar form input {
    border: 3px solid var(--bg-tertiary);
}
.tab-buttons{
    padding-bottom: 20px;
}
.tab-buttons button {
    border: none;
    padding: 20px 20px 8px 20px;
    margin: 0 2px;
    font-size: 16px;
    font-weight: 700;
    color: var(--text-primary);
    background: transparent;
    border-bottom: solid 2px transparent;
    transition: border 0.3s;
}
.tab-buttons button.active {
    border-bottom: solid 2px white;
}
.tab-buttons button:hover {
    border-bottom: solid 2px white;
  }
  .Light-Theme .tab-buttons button {
    border: none;
    border-radius: 140px;
    padding: 12px;
    font-size: 16px;
    font-weight: 700;
    color: var(--bg-primary);
    background: transparent;
  }
  .Light-Theme .tab-buttons button.active {
    background: var(--bg-tertiary);
    color: #FFF;    
  }
  .Light-Theme .tab-buttons button:hover {
    background: var(--bg-tertiary);
    color: #FFF;   
  }
.Light-Theme .search-bar form button img {
    filter: invert(1);
}
.badge {
    position: absolute;
    height: 20px;
    width: 20px;
    font-size: 12;
    background-color: rgb(239, 71, 111);
    border-radius: 50%;
    margin-top: -15px;
    margin-left: -15px;
    text-align: center;
    justify-content: center;
    align-content: center;
    align-items: center;
    display: flex;
  }
.search-bar form button {
  position: absolute;
  top: 50%;
  right: 10px;
  background: transparent;
  border: 0;
  transform: translateY(-50%);
  line-height: 100%;
}
.search-bar a {

  color: rgb(255 255 255 / 50%);
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
}
.search-bar a img:first-child{
    margin-left: 5px;
}
.search-bar a img {
  margin-left: 0;
  position: relative;
  top: -1px;
}
.search-bar ul {}
.search-bar ul li:first-child{
    margin-right: 24px;
}
.search-bar ul li:nth-child(2){
    margin-right: 24px;
}
.search-bar ul li {
    margin-right: 16px;
}
.search-bar ul li:last-child {
    margin-right: 0px;
}
.search-bar ul li form{

}
.search-bar .navbar-nav {
  flex-direction: row !important;
}
.search-bar ul .nav-item{}
.search-bar ul .nav-item .nav-link{
    /*background: #271433;*/
    border-radius: 8px;
    padding: 10px 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    color: var(--text-primary);
}
.search-bar ul li .nav-item .nav-link img {
    margin-left: 8px;
}
.search-bar ul li .nav-item .nav-link span{}
header .collapse .navbar-nav{
    display: flex;
    align-content: center;
    justify-content: center;
    justify-items: center;
    align-items: center;
}
header .collapse .navbar-nav .nav-item {
    align-self: center;
    display: flex;
    align-items: center;
}
header .collapse .navbar-nav .nav-item.text-nav-link{}
header .collapse .navbar-nav .nav-item.text-nav-link a{
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
/* identical to box height */
    color: var(--text-primary);
}
header .collapse .navbar-nav .nav-item.text-nav-link a span{}
header .collapse .navbar-nav .nav-item.Profile-nav-link {}
header .collapse .navbar-nav .nav-item.Profile-nav-link a {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
/* identical to box height */
    color: var(--text-primary);
}
header .collapse .navbar-nav .nav-item.Profile-nav-link a img {
    margin-right: 5px;
}
header .collapse .navbar-nav .nav-item.Profile-nav-link a span {}
header .collapse .navbar-nav .nav-item.button1 {}
header .collapse .navbar-nav .nav-item.button1 {}
header .collapse .navbar-nav .nav-item.button1 {
    position: relative;
    z-index: 9;
    margin-left: 16px ;
}
input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    cursor: pointer;
    height: 16px;
    width: 16px;
    background-image: url("../../public/icons/cross.svg");
    background-repeat: no-repeat;
}
.form-control::placeholder {
  color: #dbe1eb;
  font-weight: normal;
}
.form-control:focus {
  color: #fff;
  background: rgba(0, 0, 0, 0.2);
  outline: 0;
  box-shadow: 0 0 0 1px transparent;
}
header .collapse .navbar-nav .nav-item.button1 .nav-link {
  position: relative;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: var(--text-primary);
  padding: 8px 16px;
  transition: all ease .6s;
}

header .collapse .navbar-nav .nav-item.button1 .nav-link:hover,header .collapse .navbar-nav .nav-item.button2 .nav-link:hover{
    background: #fff;
    color: var(--bg-primary) !important;
}
header .collapse .navbar-nav .nav-item.button2 .nav-link {
  margin-left: 16px;
  background: var(--bg-tertiary);
  border-radius: 8px;
  padding: 8px 16px !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: var(--text-primary);
  transition: all ease .6s;
}
header .collapse .navbar-nav .nav-item.button3 {
    margin-left: 16px;
}
header .collapse .navbar-nav .nav-item.button3 .nav-link {
    background: #2a2d33;
    border-radius: 100px;
    width: 40px;
    height: 40px;
    padding: 8px;
}
.noti-cuarcale {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    width: 16px;
    height: 16px;
    color: var(--text-primary);
    border-radius: 50%;
    background: #F6543E;
    display: inline-block;
    text-align: center;
    padding-top: 1.1px;
    position: absolute;
    right: 3px;
    bottom: 3px;
}
header .collapse .navbar-nav .nav-item .nav-link{
    position: relative;
}
header .collapse .navbar-nav .nav-item .nav-link .dot-cuarcale {
    background: #F6543E;
    height: 5.408604145050049px;
    width: 5.408604145050049px;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: 14px;
    right: 8px;
}
header .collapse .navbar-nav .nav-item .nav-link svg {}
header .collapse .navbar-nav .nav-item .nav-link svg path{}
/* header end */
/* wrapper start */
.wrapper {
    padding-top: 100px;
    padding-left: 70px;
    /* height: 100vh; */
    max-height: 100vh;
    transition: all ease .75s;
}
.wrapper:has(.profile-body) {
    overflow-x: hidden;
}
.shadow-box{
    box-shadow: 5px 5px 5px 0px #000000ba !important;
}
.wrapper .left-item {
    position: relative;
    position: fixed;
    top: 0px;
    left: 0;
    height: 100%;
    max-height: 100%;
    padding: 30px 25px 30px 25px;
    padding-top: 33px !important;
    -ms-overflow-style: none;
    background: #111417;
    scrollbar-width: none;
    transition: all ease .6s;
}
.hide-leftText{
    transition: all ease .6s;
}
.wrapper .left-item::-webkit-scrollbar {
    display: none;
}
.wrapper .left-item .inner-left-item{
    padding-top: 60px;
}
.wrapper .left-item .item1{}
.wrapper .left-item .item1 h4 {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 20px;
    color: var(--text-primary);
    opacity: 0.5;
    text-transform: uppercase;
}
.wrapper .left-item .item1 ul {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 20px;
    padding-bottom: 10px;
}
.wrapper .left-item .item1 ul li:last-child{
/* margin-bottom: 0; */
}
.wrapper .left-item .item1 ul li {
    margin-bottom: 10px;
}
.wrapper .left-item .item1 ul li a {
    transition: all ease .6s;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    opacity: .5;
    overflow: auto;
}
.wrapper .left-item .item1 ul li a.active,
.wrapper .left-item .item1 ul li a:hover{
    color: #fff;
    opacity: 1;
}
.wrapper .left-item .item1 ul li a.active{
    position: relative;
}
.wrapper .left-item .item1 ul li a svg {
    width: 18px;
}
.wrapper .left-item .item1.item04{}
.wrapper .left-item .item1.item04 h4{}
.wrapper .left-item .item1.item04 ul{}
.wrapper .left-item .item1.item04 ul li {}
.wrapper .left-item .item1.item04 ul li button {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.005em;
    color: rgb(255 255 255 / 50%);
    background: transparent;
    border: none;
    margin-top: 10px;
    text-transform: capitalize;
}
.wrapper .left-item .item1.item04 ul li button:hover {
    color: #fff;
}
.wrapper .left-item .item1.item04 ul li button:hover img{
    opacity: 1;
}
.wrapper .left-item .item1.item04 ul li button img {
    margin-right: 12px;
    opacity: .5;
    position: relative;
    top: -1px;
}
.wrapper .left-item .item1.item04 ul li button img.arrow-up {
    transform: rotate(179deg);
    position: relative;
    top: -1px;
}
.wrapper .left-item .item1.item04 ul li a{
    opacity: 1;
    position: relative;
    display: block;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: var(--text-primary);
}
.wrapper .left-item .item1.item04 ul li a img {
    max-width: 25px;
    margin-right: 12px;
}
.wrapper .left-item .item1.item04 ul li a span.p{}
.wrapper .left-item .item1.item04 ul li a:hover span.number{
    color: #fff;
}
.wrapper .left-item .item1.item04 ul li a span.number {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    text-align: right;
    color: rgb(255 255 255 / 50%);
    transition: all ease .6s;
}
.wrapper .left-item .item1.item04{}
.wrapper .left-item .item1.item04{}
.wrapper .left-item .item2 {}
.wrapper .left-item .item2 a {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
    color: rgb(255 255 255 / 30%);
    margin-right: 5px;
}
.wrapper .left-item .item2 a:hover{
    color: #fff;
}
.wrapper .left-item .item2 p{
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--text-primary);
    opacity: 0.3;
    margin-top: 10px;
    margin-bottom: 0;
}
/* mide-item start */
.mide-item{}
.mide-item .Top-Artists {
    padding: 0px 0;
    margin-bottom: 20px;
}
.mide-item .Top-Artists h4 {
    margin-bottom: 40px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    color: var(--text-primary);
}
.mide-item .Top-Artists h4 img {
    margin-left: 5px;
    position: relative;
    top: -2px;
}
.mide-item .Top-Artists .top-artist-banner{}
.mide-item .Top-Artists .banner-item{
    margin: 0 15px;
}
.mide-item .Top-Artists .banner-item a{
    position: relative;
}
.mide-item .Top-Artists .banner-item a img{
    display: block;
    margin: auto;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-bottom: 5px;
}
.mide-item .Top-Artists .banner-item .subscribe-card a img{
    display: block;
    margin: auto;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 5px;
}
.mide-item .Top-Artists .banner-item a .box{
    position: relative;
    display: inline-block !important;
}
.mide-item .Top-Artists .banner-item a .box .img-check {
    width: 22px;
    height: auto;
    position: absolute;
    right: -2px;
    top: 0;
}
.mide-item .Top-Artists .banner-item a .p {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: var(--text-primary);
    margin-bottom: 0;
}
.mide-item .Top-Artists .banner-item .subscribe-card a .p {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: var(--text-primary);
    margin-bottom: 0;
}
.mide-item .Top-Artists .banner-item a .box-img {
    display: block;
    position: relative;
}
.mide-item .Top-Artists .banner-item a .number-item {
    font-weight: 600;
    font-size: 10px;
    line-height: 135%;
    color: var(--text-primary);
    height: 24px;
    display: block;
    width: 24px;
    background: #A768FF;
    border: 2px solid #180A21;
    box-sizing: border-box;
    border-radius: 50%;
    text-align: center;
    padding-top: 3px;
    position: absolute;
    bottom: 0;
    right: 14%;
}
.mide-item .top-songs {
    padding-bottom: 38px;
}
.mide-item .top-songs h4 img{
    margin-left: 5px;
}
.mide-item .top-songs h4{
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    margin-left: 10px;
}
.mide-item .top-songs .top-songs-slider{}
/* .mide-item .top-songs .slider{
    margin: 0 10px;
} */
.mide-item .top-songs .slider {
    position: relative;
    transition: all ease .6s;
}
.mide-item .top-songs .slider a.d-block{
    position: relative;
    transition: all ease .6s;
}
/* .mide-item .top-songs .slider:hover a.d-block::before,.mide-item .top-songs .slider.active a.d-block::before{
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    width: 100%;
    height: 85%;
    background: #180A21;
    opacity: 0.5;
    z-index: 999;
} */

.mide-item .top-songs .slider .ex-a img {
    width: 100%;
    margin: auto;
    position: relative;
    transition: all ease .6s;
}
.mide-item .top-songs .slider .ex-a img:first-child {
  z-index: 9;
  position: relative;
  /*  filter: drop-shadow(20px 20px 30px rgba(0, 0, 0, 0.7)); */
  box-shadow: none !important;
}
.mide-item .top-songs .slider .ex-a .piano-shadow {
    position: relative;
    background: #180a21;
}
.mide-item .top-songs .slider .ex-a .title-item {
    padding: 5px;
}
.mide-item .top-songs .slider .ex-a .piano-shadow img {
    margin-top: 0;
    margin-bottom: 0;
    position: relative;
}
.mide-item .top-songs .slider .ex-a .piano-shadow::before{
    background: linear-gradient(180deg, rgba(24, 10, 33, 0.6) 0%, rgba(24, 10, 33, 0) 100%);
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    width: 100%;
    height:100%;
    opacity: 0.5;
    z-index: 999;
}
.mide-item .top-songs .slider .ex-a .title-item .singer-name {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: var(--text-primary);
    opacity: 0.5;
}
.mide-item .top-songs .slider .ex-a .title-item .singer-name a:hover {
    text-decoration: underline;
}
.mide-item .top-songs .slider .ex-a .title-item .songstitle {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: var(--text-primary);
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mide-item .top-songs .slider ul {}
.mide-item .top-songs .slider ul::before{
    display: none;
}
.mide-item .top-songs .slider ul li:last-child{
    margin-right: 0;
}
.mide-item .top-songs .slider ul li {
    display: inline-block;
    margin-right: 8px;
}
.mide-item .top-songs .slider ul li a:hover{
    background: #fff;
    color: #362244;
}
.mide-item .top-songs .slider ul li a {
    margin-top: 8px;
    padding: 4px 8px;
    font-weight: 600;
    font-size: 12px;
    line-height: 135%;
    text-transform: capitalize;
    color: rgb(255, 255, 255, .7);
    background: #362244;
    border-radius: 18px;
}
.media {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
}
.media-body {
    -ms-flex: 1;
    flex: 1;
}
/* mide-item end */
.mobile-bottom-menu {
  background: #2C1C37;
  padding: 15px 0;
}
.mobile-bottom-menu ul{}
.mobile-bottom-menu ul li{
    margin-right: 5px;
}
.mobile-bottom-menu ul li:last-child{
    margin-right: 0;
}
.mobile-bottom-menu ul li a {
   position: relative;
    padding: 8px 12px;
    border-radius: 8px;
}
.mobile-bottom-menu ul li a.active {
    
    background: #180a21;
    
}
.mobile-bottom-menu ul li{
    display: inline-block;
}
.mobile-bottom-menu ul li.button1 a{
    position: relative;
    background: #412952;
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: var(--text-primary);
    padding: 8px 16px;
    transition: all ease .6s;
}
.mobile-bottom-menu ul li.button1 a:hover, .mobile-bottom-menu ul li.button2 a:hover {
  background: #fff;
  color: #412952 !important;
}
.mobile-bottom-menu ul li.button3 {
    margin-left: 16px;
}
.mobile-bottom-menu ul li.button3 a {
    background: #2C1C37;
    border-radius: 100px;
    width: 40px;
    height: 40px;
    padding: 8px;
}
.mobile-bottom-menu ul li.button3 a {}
.mobile-bottom-menu ul li.button2 a {
  margin-left: 16px;
  background: #A768FF;
  border-radius: 8px;
  padding: 8px 16px !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: var(--text-primary);
  transition: all ease .6s;
}
.mobile-bottom-menu ul li .dot-cuarcale {
    background: #F6543E;
    height: 5.408604145050049px;
    width: 5.408604145050049px;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: 10px;
    right: 6px;
}
.home-banner{
    background: #2C1C37;
    border-radius: 16px;
    padding: 40px;
}
.home-banner h1 img{
    margin-left: 5px;
}
.home-banner h1{
    margin-bottom: 0;
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
    color: var(--text-primary);
}

/* Contribution start */
.Contribution{
    padding-top: 24px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 48px;
}
.Contribution h2{
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    color: var(--text-primary);
    margin-bottom: 24px;

}
.Contribution h2 img{
    margin-left: 5px;
}
.Contribution .item-left{}
.Contribution .item-left h4{
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: rgb(255, 255, 255, .5);
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.Contribution .item-left .top-item{}
.Contribution .item-left .top-item .media{
    background: #271433;
    border-radius: 16px;
    padding: 24px;
}
.Contribution .item-left .top-item .media img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 16px;
}
.Contribution .item-left .top-item .media-body{}
.Contribution .item-left .top-item .media-body h4 span{
    font-weight: 500;
    font-size: 12px;
    line-height: 135%;
    color: rgba(255, 255, 255, 0.7);
}
.Contribution .item-left .top-item .media-body h4 {
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: var(--text-primary);
  border: none;
  margin-bottom: 0;
  padding-bottom: 8px;
}
.Contribution .item-left .top-item .media-body p{
    margin-bottom: 16px;
}
.Contribution .item-left .top-item .media-body .box-inner{

}
.Contribution .item-left .top-item .media-body .box-inner .media{
   background: #180A21;
   border-radius: 12px;
   padding: 12px 16px;
}
.Contribution .item-left .top-item .media-body .box-inner .media img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 12px;
    background: #A768FF;
    border: 3px solid transparent;
    padding: 4px;
}
.Contribution .item-left .top-item .media-body .box-inner .media .media-body{
    align-self: center;
}
.Contribution .item-left .top-item .media-body .box-inner .media .media-body h3{
    font-weight: 600;
    font-size: 12px;
    line-height: 135%;
    text-transform: capitalize;
    color: var(--text-primary);
    margin-bottom: 0;


}
.Contribution .item-left .top-item:last-child {
    margin-top: 24px;
}
.Contribution .item-left .top-item .media-body .box-inner .media .media-body h3 span{
    font-weight: 400;
    font-size: 12px;
    line-height: 135%;
    text-transform: lowercase;
    color: var(--text-primary);
}
.Contribution .item-right{}
.Contribution .item-right h3{
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: rgb(255, 255, 255, .5);
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.Contribution .item-right .con-item{
    margin-bottom: 26px;
}
.Contribution .item-right .con-item:last-child{
    margin-bottom: 0;
}
.Contribution .item-right .con-item .media{}
.Contribution .item-right .con-item .media img{
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-right: 16px;
}
.Contribution .item-right .con-item .media .media-body{}
.Contribution .item-right .con-item .media .media-body h4{
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    color: var(--text-primary);
    margin-bottom: 4px;
}
.Contribution .item-right .con-item .media .media-body p{
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: rgb(255, 255, 255, .5);
}
/* Contribution end */

.Search-Results-for{
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 15px;
    color: rgba(255, 255, 255, 0.5);
}
.Search-Results-for span{
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: var(--text-primary);
}

.mide-link-hover {
    position: absolute;
    display: block;
    top: 23%;
    z-index: 999;
    left: 0;
    right: 0;
    display: none;
    transition: all ease .6s;
}
.mide-item .top-songs .slider.ex-hover .mide-link-hover,
.mide-item .top-songs .slider.active .mide-link-hover {
    display: block;
} 
.mide-link-hover a{
    background: #A768FF;
    border-radius: 8px;
    padding: 4px 16px;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    color: var(--text-primary);
    transition: all ease .6s;
}


/* item-table start */
.item-table{
    padding-bottom: 40px;
}
.item-table table{}
.item-table table thead{}
.item-table table thead tr{}
.item-table table thead tr th {
    background: transparent;
    padding: 12px 24px;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: var(--text-primary);
    opacity: 0.5;
    border: none;
}
.item-table .table>:not(:first-child) {
    border-top: 0;
}
.item-table table tbody{}
.item-table table tbody tr>*{
    background: #180A21;
}
.item-table table tbody tr:nth-child(1){}
.item-table table tbody tr:nth-child(1) th span{
    background-image: url(../img/1-c.svg);
}
.item-table table tbody tr:nth-child(2){}
.item-table table tbody tr:nth-child(2) th span{
    background-image: url(../img/2-c.svg);
}
.item-table table tbody tr:nth-child(3){}
.item-table table tbody tr:nth-child(3) th span{
    background-image: url(../img/3-c.svg);
}
.item-table table tbody tr th span {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #FFF5E2;
    padding-top: 4px;
}
.item-table table tbody tr th {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: var(--text-primary);
    padding: 12px 24px;
    border: transparent;
    background: transparent;
    border-radius: 10px 0px 0px 10px;
}
.item-table table tbody tr td:last-child{
    border-radius: 0px 10px 10px 0px;
}
.item-table .table>tbody>tr:nth-of-type(odd)>*{
 background: #1C0C27;
 color: #fff;
}
.item-table table tbody tr td {
    padding: 12px 24px;
    font-weight: 500;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: var(--text-primary);
    border: transparent;
    background: transparent;
}
.item-table table tbody tr td{}
.item-table table tbody tr td img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 16px;
}
.item-table .pagination{
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 40px;
}
.item-table .pagination li{
    transition: all ease .6s;
    margin-right: 5px;
}
.item-table .pagination li:last-child{
    margin-right: 0;
}
.item-table .pagination li:first-child a{
    background: #2C1C37;
    padding: 8px 16px;
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.5);
    border: none;
}
.item-table .pagination li:last-child a{
    background: #2C1C37;
    border-radius: 8px;
    padding: 8px 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.5);
    border: none;
}
.item-table .pagination li a{
    background: transparent;
    border-radius: 8px;
    padding: 10px 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    color: rgba(255, 255, 255, .5);
    border: none;
    transition: all ease .6s;
}
.item-table .pagination li.active a,.item-table .pagination li:hover a{
   background: #A768FF;
   color: #fff;
}
/* item-table end */
.mide-item .nav_body ul.nav.nav-pills.mb-3::before{
    display: none;
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: #212227;
    margin-bottom: 4px;
}
.mide-item .nav_body ul {
    position: relative;
}
.mide-item .nav_body ul li button {
    background: transparent;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: rgb(255 255 255 / 50%);
    border-bottom: 2px solid transparent;
    border-radius: 0;
}
.mide-item .nav_body .nav-pills .nav-link.active, .mide-item .nav_body .nav-pills .show>.nav-link {
    color: #fff;
    background-color: transparent;
    border-color: #fff;
    padding-bottom: 4px;
}
.mide-item .nav_body ul li {
    margin-right: 10px;
    position: relative;
}
.mide-item .profile-body.nav_body ul li:last-child {
    /* margin-right: 0;
    width: 328px;
    margin-left: auto; */
} 
.mide-item .nav_body ul li.coustom-nav-body {
    margin-right: 0 !important;
    width: 328px !important;
    margin-left: auto !important;
}
.mide-item .nav_body ul li.coustom-nav-body .search-bar {
    position: relative;
    top: -8px;
}
.mide-item .nav_body ul li.custom-nav-body .search-bar {
    position: relative;
    top: -8px;
    left: 50%;
}
.mide-item .nav_body ul li:last-child {
    margin-right: 0;
    width: auto;
    margin-left: 0;
}

/* arrow-top-left start */
.arrow-top-left{}
.arrow-top-left a{}
.arrow-top-left a img{}
/* arrow-top-left end */
/* profile-item start */
.profile-item {
    margin-bottom: 10px;
}
.profile-item .media{}
.profile-item .media .profile-images{
    margin-right: 48px;
    width: 180px;
    height: 180px;
    border-radius: 50%;
}
.profile-item .media-body{}
.profile-item .media-body h2 img {
    margin-left: 5px;
    max-width: 28px;
    width: 28px;
    height: 28px;
}
.profile-item .media-body h2{
    font-weight: 700;
    font-size: 48px;
    line-height: 100%;
    color: var(--text-primary);
    margin-bottom: 12px;
}
.profile-item .media-body p{
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 12px;
}
.profile-item .media-body .button-item{}
.profile-item .media-body .button-item ul{}
.profile-item .media-body .button-item ul li:last-child{
    margin-right: 0;
}
.profile-item .media-body .button-item ul li{
    display: inline-block;
    margin-right: 8px;
}
.profile-item .media-body .button-item ul li:first-child{
    margin-right: 32px;
}
.profile-item .media-body .button-item ul li button{
    padding: 8px 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: var(--text-primary);
    background: var(--bg-tertiary);
    border-radius: 8px;
    border: 0;
}
.profile-item .media-body .button-item ul li button.subscribed{
    color: #180A21;
    background: #E9E9ED;
}
.subscribe-card{
    display: flex;
    margin: 20px 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mide-item .profile-body .subscribe-card ul li button{
    padding: 8px 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: var(--text-primary);
    background: #A768FF;
    border-radius: 8px;
    border: 0;
}
.Light-Theme .mide-item .profile-body .subscribe-card ul li button.subscribed{
    color: #180A21;
    background: #E9E9ED;
}
.mide-item .profile-body .subscribe-card ul li button.subscribed{
    color: var(--text-primary);
    background: #1c1c1c;
    border: solid 1px #5b5b5b;
}
.Light-Theme .subscribe-card{
    background-color: #F9F9FA;
}
.profile-item .media-body .button-item ul li a{
    background: #362244;
    border-radius: 18px;
    padding: 4px 8px;
    font-weight: 600;
    font-size: 12px;
    line-height: 135%;
    text-transform: capitalize;
    color: var(--text-primary);

    opacity: 0.7;
}
.profile-item .media .box-img{
    position: relative;
    margin-right: 48px;
}
.profile-item .media .box-img img {
    margin-right: 0;
}
.profile-item .media .box-img span {
    width: 40px;
    height: 40px;
    display: inline-block;
    border: 2px solid #180a21;
    border-radius: 50%;
    padding: 8px 5px;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    background: #A768FF;
    position: absolute;
    right: 2px;
    bottom: 2px;
}
.profile-item .media .left-side{}
.profile-item .media .left-side ul{}
.profile-item .media .left-side ul li{
    display: inline-block;
}
.profile-item .media-body p.location {
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    color: var(--text-primary);
}
.profile-item .media-body p.location sub{
    font-weight: 600;
    font-size: 12px;
    line-height: 135%;
    text-transform: capitalize;
    color: rgba(255, 255, 255, 0.5);
    display: inline-block;
    margin-left: 8px;
    bottom: 2px;

}
.profile-item .location img {
    margin-right: 8px;
    width: 24px;
}
.profile-item .media .left-side ul li{
    margin-right: 20px;
}
.profile-item .media .left-side ul li:last-child{
    margin-right: 0;
}
.profile-item .media .left-side ul li a {
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    color: rgba(255, 255, 255, 0.7);
}
.profile-item .media .left-side ul li a img{
    margin-right: 8px;
}
.profile-item .media .right-side{}
.profile-item .media .right-side ul{}
.profile-item .media .right-side ul li:last-child{
    margin-right: 0;
}
.profile-item .media .right-side ul li{
    display: inline-block;
    margin-right: 24px;
}
.profile-item .media .right-side ul li a{}
.profile-item .media .right-side ul li a img{}
/* profile-item end */

.profile-body {
    padding-bottom: 20px;
}
.mide-item .profile-body ul li {
    margin-right: 2%;
    white-space: nowrap;
}
.mide-item .profile-body ul li button span{
    font-weight: 600;
    font-size: 12px;
    line-height: 135%;
    text-transform: capitalize;
    color: rgba(255, 255, 255);
    display: inline-block;
    margin-left: 4px;

}
.mide-item .profile-body ul li button {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;

}
.mide-item .profile-body .nav {
    position: relative;
}
.mide-item .profile-body .nav::before {
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
    position: absolute;
    content: "";
    width: 100%;
    height: 2px;
    bottom: 0px;
}
.mide-item .profile-body .search-bar form button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent !important;
    border: 0;
    transform: none;
    line-height: 100% !important;
}
.mide-item .profile-body .search-bar {
    position: relative;
    top: -8px;
}
table td , table th{
    vertical-align: middle;
    margin-right: 10px;
}
.table-dark {
    --bs-table-bg: transparent;
    --bs-table-striped-bg: #22112C;
    --bs-table-striped-color: #fff;
    --bs-table-active-bg: #22112C;
    --bs-table-active-color: #fff;
    /* --bs-table-hover-bg: #22112C; */
    --bs-table-hover-color: #fff;
    color: #fff;
    border-color: #2b2b2b;
    border-radius: 0;
}
.Played table{
    border-collapse:separate; 
    border-spacing: 0;
    width: 100%;
    max-width: 60vw;
    margin: 0 auto;
}
.Played table tbody tr td, .Played table thead tr th{
    font-weight: 500;
    font-size: 15px;
    line-height: 150%;
    color: rgba(255, 255, 255, .5);
}
.Played table thead tr th {
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom-color: rgb(100, 100, 100);
    white-space: nowrap;
}
.Played table thead tr th:first-child{
    padding-left: 28px;
    width: 60px;
}
.Played table tbody tr td:last-child{
    /* border-radius: 0px 8px 8px 0px; */

    line-height: 140%;
}
.Played table tbody tr td .ex-a:hover{
    background: #fff;
    color: #412952;
}
.Played table tbody tr td .ex-a{
    background: #412952;
    padding: 8px 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    border-radius: 8px;
    color: var(--text-primary);
    transition: all ease .6s;
}
.Played table tbody tr td:nth-child(3) {
    font-weight: 600;
    font-size: 15px;
    line-height: 140%;
    color: rgba(255, 255, 255, .5);
}
.Played table tbody tr td:nth-child(3) span.chip{
    margin-left: 50px;
}
.Played table tbody tr td:nth-child(2) img {
    width: 49px;
}
.Played table tbody tr th {
    padding-left: 28px;
    width: 60px;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    /* border-radius: 8px 0 0 8px; */
}
.mide-item video {
    width: 100%;
    height: auto;
}
/* tab-content-coustom start */
.mide-item .tab-content-coustom .top-songs .slider {
    margin: 15px 10px;
}
.mide-item .tab-content-coustom   .top-songs {
    padding-bottom: 0px;
}
/* tab-content-coustom end */

.Performances{
    padding: 24px;
    background: #22112C;
    border-radius: 8px;
    margin-bottom: 24px;
    width: 860px;
    max-width: 100%;
}
.Performances .media{
    position: relative;
    margin-bottom: 30px;
}
.Performances .media .per-button{}
.Performances .media .per-button button {
    background: transparent;
    border: none;
    color: white;
    font-size: 24px;
    line-height: 100%;
    position: absolute;
    right: 0;
    top: 0;
}
.Performances .media img{
    width: 64px;
    height: 64px;
    border-radius: 100%;
    margin-right: 24px;
}
.Performances .media .media-body{
    align-self: center;
}
.Performances .media .media-body h4{
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 8px;
}
.Performances .media .media-body h4 strong{
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;

    color: var(--text-primary);
}
.Performances .media .media-body p{
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 0;
}
.Performances .video_play{}
.Performances .video_play .video1{}
.Performances .video_play ul{
    padding-top: 16px;
    border-top: 2px solid rgba(255, 255, 255, 0.1);
    margin-top: 24px;
}
.Performances .video_play ul li{
    display: inline-block;
    margin-right: 24px;
}
.Performances .video_play ul li{}
.Performances .video_play ul li a{
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: var(--text-primary);
}
.Performances .video_play ul li a img{
    max-width: 20px;
    margin-right: 8px;
}
.Subscriptions{
    padding-bottom: 20px;
}
.Subscriptions .item{
    background: #22112C;
border-radius: 8px;
padding: 24px;
text-align: center;
}
.Subscriptions .item a{
    text-align: center;
}
.Subscriptions .item a img{
    width: 106px;
    height: 106px;
    border-radius: 50%;
    margin-bottom: 16px;
}
.Subscriptions .item a h4{
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: var(--text-primary);
}
.Subscriptions .item a p{
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    color: var(--text-primary);
    opacity: 0.7;
    margin-bottom: 12px;
}
.Subscriptions .item button{
    background: #412952;
    border-radius: 8px;
    padding: 8px 16px;
    border: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: var(--text-primary);
    transition: all ease .6s;
}
.Subscriptions .item button:hover{
    background: #fff;
    color: #412952;
}

/* Login start */
.Login{
    padding: 0 15px;
}
.Login .Login_heading{
    margin-bottom: 104px;
}
.Login .Login_heading a{}
.Login .Login_heading a img{}
.Login .box{
    width: 462px;
    max-width: 100%;
    margin: 0 auto;
}
.Login .box h1{
    font-weight: 600;
    font-size: 32px;
    line-height: 150%;
    margin-bottom: 8px;
    text-align: center;
    color: var(--text-primary);
}
.Login .box p{
    font-weight: 500;
font-size: 16px;
line-height: 150%;
color: rgba(255, 255, 255, 0.7);
margin-bottom: 32px;
}
.Login .box form{}
.Login .box form .mb-3{
    margin-bottom: 24px !important;
}
.Login .box form label{
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 8px;
    color: var(--text-primary);
}
.Login .box form input[type='number'],
.Login .box form input[type='email'],
.Login .box form input[type='name'],
.Login .box form input[type='password'],
.Login .box form input[type='text'],
 .Login .box form select {
    background-color: var(--bg-tertiary);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    padding: 16px 24px;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: rgba(255, 255, 255, 0.5);
}
.Login .box form .coustom-pass{
    position: relative;
}
.Login .box form .coustom-pass input {
    padding-right: 45px;
}
.Login .box form .coustom-pass button {
    background: transparent;
    border: none;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
}
.Login .box form .coustom-pass button img {}
.Login .box form .forgot {
    margin-bottom: 32px;
}
.Login .box form .forgot a:hover{
    color: #fff;
}
.Login .box form .forgot a {
    font-weight: 500;
font-size: 16px;
line-height: 150%;
text-align: right;
color: #C499FF;
transition: all ease .6s;
}
.Login .box form button.btn {
    background: #A768FF;
    border-radius: 8px;
    padding: 16px 48px;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: var(--text-primary);
    border: none;
    width: 100%;
    text-align: center;
    margin-bottom: 32px;
}
.Login .box form p {}
.Login .box form p a{
    font-weight: 500;
font-size: 16px;
line-height: 150%;
text-align: right;
color: #C499FF;
display: inline-block;
margin-left: 5px;

}
/* Login end */

.Login .box .form-check-input[type=checkbox] {
    border-radius: 0.25em !important;
}
.Login .box .form-check-input {
    width: 1em;
    margin-right: 12px;
    height: 1em;
    background-color: transparent !important;
    padding: 10px !important;
    margin-top: 0.25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: contain !important;
    border: 1.5px solid #5E636E !important;
    -webkit-appearance: none !important;
    -moz-appearance: none;
    appearance: none !important;
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
}
/* .Login .box .form-check-input:checked{
    background-color: #A768FF !important;
} */
.Login .box form .form-check {
    margin-bottom: 32px !important;
}
.Login .box form .form-check label {
    margin-bottom: 0;
    position: relative;
    top: 2px;
}
.Login .google-login{
    text-align: center;
    margin-bottom: 32px;
}
.Login .google-login a{
    background: #FFFFFF;
    border-radius: 8px;
    padding: 16px 48px;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #180A21;
    display: block;

}
.Login .google-login a img{
    margin-right: 16px;
}

.item-edit-profile-left.wrapper .left-item .item1 ul li a {
    font-size: 14px;
    line-height: 140%;
    color: var(--text-primary);
    padding: 14px 12px;
    display: block;
}
.item-edit-profile-left.wrapper .left-item .item1 ul li a.active, 
.item-edit-profile-left.wrapper .left-item .item1 ul li a:hover{
    background: var(--bg-tertiary);
    border-radius: 8px;
}
.item-edit-profile-left.wrapper .left-item .item1 ul li {
    margin-bottom: 5px;
}
.item-edit-profile-left.wrapper .left-item{
    padding-left: 0;
    padding-right: 0;
}
.item-edit-profile-left.wrapper .left-item .item2{
    padding-left: 25px;
    padding-right: 25px;
}

.form-select {
    background-image: url("../img/arrow-down.svg")
}

.Edit-Profile .Login .box {
    width: 580px;
    max-width: 100%;
    margin: 0 auto;
}

.Edit-Profile {
    margin-top: 10px;
}
.Edit-Profile .Edit-top{
    margin-bottom: 44px;
}
.Edit-Profile .Edit-top h2{
    font-weight: 600;
font-size: 24px;
line-height: 100%;
color: var(--text-primary);
margin-bottom: 0;
}
.Edit-Profile .Edit-top h2 a{}
.Edit-Profile .Edit-top h2 a img{
    margin-right: 24px;
    width: 32px;
}
.Edit-Profile .Avatar{
     max-width: 580px;
    margin: auto;
}
.Edit-Profile .Avatar h4{
    font-weight: 500;
font-size: 16px;
line-height: 150%;
display: flex;
align-items: center;
color: var(--text-primary);
margin-bottom: 16px;

}
.Edit-Profile .Avatar .media{
    
    padding-bottom: 24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 24px;
}
.Edit-Profile .Avatar .media img{
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin-right: 48px;
}
.Edit-Profile .Avatar .media .media-body{
    align-self: center;
}
.Edit-Profile .Avatar .media .media-body ul{}
.Edit-Profile .Avatar .media .media-body ul li{
    display: inline-block;
    margin-right: 16px;
}

.btn-profile {
  margin-right: 16px;
}
.btn-profile:last-child {
  margin-right: 0;
}

.Edit-Profile .Avatar .media .media-body ul li:last-child {
    margin-right: 0;
}
.Edit-Profile .Avatar .media .media-body ul li:last-child a,
.btn-profile.btn-profile-default {
    background: transparent;
    border-color: rgba(255, 255, 255, 0.2);
    color: white;
}
.Light-Theme .btn-profile.btn-profile-default {
    background: transparent;
    border-color: rgba(0, 0, 0, 0.2);
    color: var(--bg-primary);
}
.Edit-Profile .Avatar .media .media-body ul li a,
.btn-profile {
    background: white;
border-radius: 8px;
padding: 12px 24px;
border: 2px solid white;
font-weight: 600;
font-size: 16px;
line-height: 140%;
color: var(--bg-primary);
}
.Edit-Profile .Avatar .media .media-body ul li a:hover,
.btn-profile:hover {
    background: #fff !important;
    color: var(--bg-primary) !important;
    border-color: #fff !important;

}

.Light-Theme .btn-profile:hover {
    background: #000 !important;
    color: white !important;
    border-color: #000 !important;

}









.arrow-left{
    margin-bottom: 48px;
}
.arrow-left a{}
.Blinding-Lights{
    padding-bottom: 40px;
}
.Blinding-Lights .Blinding-left{}
.Blinding-Lights .Blinding-right{}
.Blinding-Lights .Blinding-right .media{
    margin-bottom: 10px;
}
.Blinding-Lights .Blinding-right .media a.user{
    position: relative;
    display: inline-block;
    margin-right: 24px;
}
.Blinding-Lights .Blinding-right .media a.user img{
    width: 280px;
    max-width: 100%;

}
.Blinding-Lights .Blinding-right .media a.user span {
    background: rgba(45, 23, 59, 0.7);
    backdrop-filter: blur(40px);
    padding: 3px 5px;
    color: var(--text-primary);
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    border-radius: 4px;
    position: absolute;
    right: 8px;
    bottom: 8px;
    z-index: 99;
}
.Blinding-Lights .Blinding-right .media .media-body{}
.Blinding-Lights .Blinding-right .media .media-body a.title{
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 8px;
    color: var(--text-primary);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.Blinding-Lights .Blinding-right .media .media-body p img{
    margin-right: 5px;
}
.Blinding-Lights .Blinding-right .media .media-body p {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: var(--text-primary);
    margin-bottom: 10px;
    opacity: 0.7;
}
.Blinding-Lights .Blinding-right .media .media-body .Margo-Piano{
    font-weight: 500;
font-size: 14px;
line-height: 140%;
color: rgba(255, 255, 255, 0.7);
}
.Blinding-Lights .Blinding-right .media .media-body .Margo-Piano img{
    margin-right: 8px;
}

.slider {
    position: relative;
    transition: all ease .75s;
    padding-bottom: 10px;
    
}

.slider .box-hover {
    position: relative;
    transition: all ease .75s;
    padding: 5px;
}
.slider .box-hover:hover,
.slider .box-hover.ex-hover {
    background: #A768FF;
    box-shadow: 0 0 15px 2px #a768ff;
}
.slider:hover .box-hover, .slider.active .box-hover{
     
}
.slider:hover .box-hover::before,.slider.active .box-hover::before{

}
/* 
.slider .box-hover::before {
    position: absolute;
    content: "";
    top: 0px;
    bottom: 0px;
    left: -0.6rem;
    background: #A768FF;
    transform-origin: 0px 100%;
    width: 0.6rem;
    transform: scaleX(0);
    transition-delay: 75ms;
    margin-bottom: -1px;
}
.slider:hover .box-hover::after, .slider.active .box-hover::after{
    transform: scaleX(1);
    transition-delay: 75ms;
}
.slider .box-hover::after {
    content: "";
    position: absolute;
    bottom: -0.51rem;
    left: -0.6rem;
    right: 0px;
    background: #A768FF;
    transform-origin: 0px 100%;
    height: 0.56rem;
    transform: scaleY(0);
    transition-delay: 75ms;
    width: 106.1%;
} */
 .slider .ex-a.d-block{
    position: relative;
    transition: all ease .6s;
    transition-delay: 75ms;
}
/* .slider:hover .overlay, */ .slider.active .overlay {
    position: absolute;
    right: 5px;
    left: 5px;
    top: 5px;
    bottom: 5px;
    width: 95%;
    height: 96%;
    background: rgb(24 10 33 / 50%);
    z-index: 999;
    transition: all ease .75s;
    transition-delay: 75ms;
    border-radius: 0px;
}

 .slider .ex-a{
    position: relative;
    transition-delay: 75ms;
 }
 .slider .ex-a .overlay {
    transition: all ease .6s;
 }
.slider.active .ex-a .overlay img {
    position: absolute !important;
    max-width: 100px;
    left: 0;
    right: 0;
    top: 35%;
    transform: translateY(-50%);
}
 .slider .ex-a img {
    width: 100%;
    margin: auto;
    position: relative;
    transition: all ease .6s;
}
 .slider .ex-a img:first-child {
  z-index: 9;
  position: relative;
  /*  filter: drop-shadow(20px 20px 30px rgba(0, 0, 0, 0.7)); */
  box-shadow: none !important;
}
 .slider .ex-a .piano-shadow {
    position: relative;
}
 .slider .ex-a .piano-shadow img{
    margin-top: 0;
    margin-bottom: 15px;
    position: relative;
} .slider .ex-a .piano-shadow::before{
    background: linear-gradient(180deg, rgba(24, 10, 33, 0.6) 0%, rgba(24, 10, 33, 0) 100%);
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    width: 100%;
    height:100%;
    opacity: 0.5;
    z-index: 999;
}
 .slider .ex-a .title-item .singer-name{
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: var(--text-primary);
    opacity: 0.5;
}
.slider .ex-a .title-item .singer-name a:hover{
    text-decoration: underline;
}
 .slider .ex-a .title-item .songstitle {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: var(--text-primary);
}

 .slider ul {}
 .slider ul li:last-child{
    margin-right: 0;
}
 .slider ul li {
    display: inline-block;
    margin-right: 8px;
}
 .slider ul li a:hover{
    background: #fff;
    color: #362244;
}
 .slider ul li a {
    margin-top: 8px;
    padding: 4px 8px;
    font-weight: 600;
    font-size: 12px;
    line-height: 135%;
    text-transform: capitalize;
    color: rgb(255, 255, 255, .7);
    background: #362244;
    border-radius: 18px;
}



.Video-Tutorial{}
.Video-Tutorial .video-tutorial-left{}
.Video-Tutorial .video-tutorial-left .nav_body{}
.Video-Tutorial .video-tutorial-left .nav_body ul{
    position: relative;
    margin-top: 24px;
}
.Light-Theme .Video-Tutorial .video-tutorial-left .nav_body ul{
    position: relative;
    margin-top: 24px;
}
.Video-Tutorial .video-tutorial-left .nav_body ul li:last-child {
    width: 200px;
    text-align: right;
    margin-left: auto;
}
.Video-Tutorial .video-tutorial-left .nav_body ul li:last-child .formFile {}
.Video-Tutorial .video-tutorial-left .nav_body ul li .formFile label {
    background: #43324B;
    border-radius: 8px;
    padding: 8px 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: var(--text-primary);
    position: relative;
    top: -8px;
}
.Video-Tutorial .video-tutorial-left .nav_body ul li .formFile label img {
    margin-right: 8px;
    position: relative;
    top: -2px;
}
.Video-Tutorial .video-tutorial-left .nav_body ul li{
    margin-right: 0;
}
.Light-Theme .Video-Tutorial .video-tutorial-left .nav_body ul li{
    margin-right: 0;
    background-color: #fff;
}
.Video-Tutorial .video-tutorial-left .nav_body ul li button{
    padding: 20px 30px;
    padding-top: 0;
} 
/* .add-comment{
    padding-top: 20px;
} */
.add-comment .media{}
.add-comment .media img{
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-right: 10px;
}
.add-comment .media .media-body{}
.add-comment  form{}
.add-comment  form input{
    background: var(--bg-secondary);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 20px */
    padding: 14px 16px;
    color: rgba(255, 255, 255, 0.5);
}
.add-comment   form .comment-button {
    padding-bottom: 20px;
}
.add-comment  form .comment-button button, .sng-btn, #unfinished-btn {
  background: var(--bg-tertiary);
  border-radius: 8px;
  padding: 8px 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: var(--text-primary);
  border: none;
}
.add-comment  form .comment-button button:last-child{}
.Video-Tutorial .user-comment {
    margin-bottom: 20px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 10px;
}
.Video-Tutorial .user-comment:first-child{
    padding-top: 10px;
}
.Video-Tutorial .user-comment:last-child{
    border: none;
    padding-bottom: 0;
}
.Video-Tutorial .user-comment .media{}
.Video-Tutorial .user-comment .media .user-img{}
.Video-Tutorial .user-comment .media .user-img img{
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-right: 24px;
}
.Video-Tutorial .user-comment .media .user-body{
    color: #fff;
}
.Video-Tutorial .user-comment .media .user-body h4{
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    color: var(--text-primary);
}
.Video-Tutorial .user-comment .media .user-body h4 span{
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: var(--text-primary);

opacity: 0.5;
}
.Video-Tutorial .user-comment .media .user-body p{
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 12px;
    color: #CCCCCC;
    overflow-wrap: break-word;
}
.Video-Tutorial .user-comment .media .user-body ul{}
.Video-Tutorial .user-comment .media .user-body ul li {
    display: inline-block;
    width: auto;
}
.Video-Tutorial .user-comment .media .user-body ul li a:hover{
    color: #fff;
}
.Video-Tutorial .user-comment .media .user-body ul li a{
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    color: var(--text-primary);
    margin-right: 24px;

}
.Video-Tutorial .user-comment .media .user-body ul li a img{
    margin-right: 8px;
    position: relative;
    top: -2px;
}
.Video-Tutorial .user-comment .media .user-body ul li button{
    padding: 0;
    margin: 0;
    border: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: var(--text-primary);
}
.Video-Tutorial .lock-right-item {
    padding-right: 20px;
}
.Video-Tutorial{}
.Video-Tutorial{}

.Video-Tutorial .lock-right-item .Blinding-Lights .Blinding-right .media a.user img {
    width: 100px;
    max-width: 100%;
}

.Video-Tutorial .lock-right-item .Blinding-Lights .Blinding-right .media .media-body a.title {
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 12px;
    line-height: 135%;
    color: var(--text-primary);
}

.Video-Tutorial .lock-right-item .Blinding-Lights .Blinding-right .media .media-body p {
    font-weight: 400;
    font-size: 12px;
    line-height: 135%;
    color: var(--text-primary);
    opacity: 0.7;
}


.img-box{
    position: relative;
}
.unlock {
    text-align: center;
    width: fit-content;
}
.unlock img{
     margin-bottom: 4px;
     width: 24px;
}
.unlock .h4{
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: var(--text-primary);
}
.unlock button{
    font-weight: 600;
    font-size: 12px;
    line-height: 135%;
    background: #412952;
    border-radius: 100px;
    padding: 6px 12px;
    border: none;
    text-transform: capitalize;
    color: var(--text-primary);
    transition: all ease .6s;
}
.unlock button:hover{
    opacity: .7;
}
.unlock button img{
    margin-right: 4px;
}
.Video-Tutorial .lock-right-item .slider:hover a.d-block::before, .Video-Tutorial .lock-right-item .slider.active a.d-block::before {
    height: 83%;
    left: 0;
    top: 0;
    transition-delay: 75ms;
    transform: none;
}
.Video-Tutorial .lock-right-item .slider .ex-a .title-item .songstitle {
    font-size: 14px;
    line-height: 22px;
}


.Notifications{
    position: relative;
}
.Notifications::before {
    position: absolute;
    content: "";
    left: 0;
    right: 15px;
    top: -4px;
    margin: 0 auto;
    background: #362244;
    border-radius: 4px;
    width: 30px;
    height: 30px;
    transform: rotate(40deg);
}
.Notifications .top-noti{
    padding: 24px;
}
.Notifications .top-noti h4{
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: var(--text-primary);
    margin-bottom: 0;
}
.Notifications .top-noti a{
    font-weight: 500;
font-size: 14px;
line-height: 140%;
 
text-align: right;
color: #C499FF;
}
.Notifications .top-noti a img{
    margin-right: 8px;
}
.Notifications h3{
    background: #2B1F32;
    padding: 12px 24px;
    font-weight: 600;
font-size: 14px;
line-height: 140%;
letter-spacing: 0.04em;
color: rgba(255, 255, 255, 0.7);
}
.Notifications .item{
    padding: 16px 24px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
}
/* header .dropdown-menu .Notifications .item:last-child{
    border-bottom: 0;
} */
.Notifications .item2{}
.Notifications .item2 .box-img2 {
    margin-right: 16px;
}
.Notifications .item2 .box-img2 img{
    width: 48px;

}
.Notifications .item a{}
.Notifications .item .media{}
.Notifications .item .media .box-img {
    margin-right: 16px;
    position: relative;
}
.Notifications .item .media .box-img .hash-number {
    background: #A768FF;
    border: 2px solid #3C2D44;
    padding: 3px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    text-align: center;
    font-weight: 600;
    font-size: 10px;
    line-height: 135%;
    position: absolute;
    right: -4px;
    bottom: -4px;
}
.Notifications .item-all{
    padding: 16px 24px 24px 24px;
}
.Notifications .item-all a{
    font-weight: 500;
font-size: 14px;
line-height: 140%;
color: #C499FF;
}
.Notifications .item .media .box-img img{
    width: 48px;
    height: 48px;
    border-radius: 50%;

}
.Notifications .item .media-body{}
.Notifications .item .media-body h6{
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    margin-bottom: 8px;
    color: var(--text-primary);

}
.Notifications .item .media-body p{
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 8px;

}
.Notifications .item .media-body p strong{
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: var(--text-primary);
}
.Notifications .item .media-body small{
    font-weight: 500;
font-size: 14px;
line-height: 140%;
color: rgba(255, 255, 255, 0.5);
display: block;
}
.nav-drop .dropdown-menu[data-bs-popper] {
    top: 130%;
    left: -180px;
    margin-top: 0.125rem;
}
.nav-drop .dropdown-menu,.Notifications-body {
    min-width: 416px;
    padding: 0;
    background: #362244;
box-shadow: 0px 10px 10px rgba(16, 10, 85, 0.1);
border-radius: 8px;
}




.top-songs-body .search-bar form input {
    width: 220px;
    padding: 10px 16px;
    padding-right: 40px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: #2C1C37;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: rgb(255 255 255 / 50%);
}
.top-songs-body .filter{}
.top-songs-body .filter .btn.dropdown-toggle {
    background: var(--bg-tertiary);
    border-radius: 8px;
    padding: 9px 10px;
    margin-left: 16px;
}
.top-songs-body .filter .dropdown-toggle::after{
    display: none;
}
.top-songs-body tr td .text-center button {
    background: transparent;
    border: none;
}
.top-songs-body{}


.top-songs-body .Played table thead tr th{
    background: transparent;
    border-bottom: 1px solid rgba(255, 255, 255, .5);
    border-radius: 0;
}


.filter{}
.filter .btn-group{}
.filter .btn-group button{}
.filter .btn-group button img{}
.filter .dropdown-menu{
    min-width: 248px;
    background: var(--bg-tertiary);
    padding: 0;
}
.filter .dropdown-menu form .filter-check-all{
    padding: 15px 24px;

}
.filter .dropdown-menu form{}
.filter .dropdown-menu form .filter-item{
    padding: 16px 24px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
}
.filter .dropdown-menu form .filter-item h4{
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: var(--text-primary);
    margin-bottom: 0;
}
.filter .dropdown-menu form .filter-item a{
    font-weight: 500;
font-size: 14px;
line-height: 140%;
text-align: right;
color: #C499FF;
}
.filter .dropdown-menu form h6 {
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: var(--text-primary);
    margin-bottom: 10px;
}
.filter .dropdown-menu form .form-check-input {
    width: 1em;
    height: 1em;
    margin-top: 0.3em;
    vertical-align: top;
    background-color: transparent;
    border: 1.5px solid #5E636E !important;
}
.filter .dropdown-menu form .form-check {
    margin-bottom: 0.3rem !important;
}
.filter .dropdown-menu form .form-check input:checked{
    /* background: #A768FF;
 */
}
.filter .dropdown-menu form .form-check label{
    font-weight: 500;
    font-size: 12px;
    line-height: 135%;
    color: var(--text-primary);
}
.filter .form-check-input:checked[type=checkbox] {
    background-color: #A768FF !important;
}
.filter{}
.filter{}

.dropdown-menu[data-bs-popper] {
    top: 100%;
    left: auto;
    margin-top: 0.125rem;
    right: 0;
}


.arrow-show-hide-toggoler {
    margin-right: 15px;
}
.arrow-show-hide-toggoler button{
    background: transparent;
    border: none;
    padding: 0;
        width: 40px;
    height: 40px;
    padding: 8px;
    box-sizing: border-box;
        vertical-align: middle;
    color: inherit;
    outline: none;
    background: none;
    margin: 0;
    border: none;
    padding: 0;
    line-height: 0;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
    text-align: center;
    border-radius: 50%;
}
.arrow-show-hide-toggoler button:hover,.arrow-show-hide-toggoler button:focus:not(:focus-visible){
    background: rgba(255, 255, 255, .2);
}
.collapses-left-item .hide-leftText{
    display: none;
}
.arrow-show-hide-toggoler button svg {
    fill: #fff;
    max-width: 28px;
    margin: 0 auto;
    display: block !important;
}
.collapses-left-item .wrapper .left-item .item1.item04 ul li button {
    font-size: 12px;
    line-height: 120%;
}
.collapses-left-item .wrapper .left-item .item1.item04 ul li button img {
    margin-right: 6px;
    position: relative;
    top: -1px;
}
.collapses-left-item .wrapper .left-item .item05 ul{
    border-bottom: 0;
}
.collapses-left-item {}
.collapses-left-item .wrapper .left-item .item1 h4 {
    font-size: 10px;
    line-height: 18px;
    margin-bottom: 8px;
}
.collapses-left-item .wrapper .left-item {
    text-align: center;
    transition: all ease .6s;
    padding: 30px 8px 30px 8px;
}
.collapses-left-item .wrapper {
    /* padding-left: 8.666667%; */
}
.collapses-left-item .wrapper .left-item .item1 ul li a svg {
    width: 30px;
}
.song-list .swiper-slide{
    padding-top: 10px;
    padding-bottom: 10px;

 }

.song-list .swiper-slide-active{
    scale: 1 !important;
    transition: .2s;
}
.song-list .swiper-slide-next{
    scale: 0.9 !important;
    transition: .2s;
}
.song-list .swiper-slide-prev{
    scale: 0.9 !important;
    transition: .2s;
}
.song-list .swiper-slide{
    scale: 0.8;
    transition: .2s;
}
/* 
============================================================================================
======================================= Light-Theme start ==================================
============================================================================================
 */

.Light-Theme{
    background: #fff;
}
.Light-Theme .search-bar form input {
    background: #F4F4F6;
    border: 2px solid var(--bg-tertiary);
    color: var(--bg-tertiary);
}
.Light-Theme .search-bar form input::placeholder {
    color:rgba(0, 0, 0, 0.5);
}
.Light-Theme header {
    border-bottom: 1px solid rgba(24, 10, 33, 0.1);
    background: #ffffff;
}
.Light-Theme footer {
    border-top: 1px solid rgba(24, 10, 33, 0.1)
}
.Light-Theme .search-bar a {
    color: rgb(24 10 33 / 50%);
}
.Light-Theme .search-bar ul .nav-item .nav-link {
    /*background: #F4F4F6;*/
    color: #180A21;
}
.Light-Theme header .collapse .navbar-nav .nav-item .nav-link .dot-cuarcale {
    top: 14px;
    right: 11px;
}
.Light-Theme header .collapse .navbar-nav .nav-item.button1 .nav-link {
    background: #E9E9ED;
    color: var(--bg-primary);
}
.Light-Theme header .collapse .navbar-nav .nav-item.button3 .nav-link {
    background: #E9E9ED;
}
.Light-Theme .wrapper .left-item .item1 ul li a {
    color: var(--bg-primary);
}
.Light-Theme .wrapper .left-item .item1 ul li a.active, .Light-Theme .wrapper .left-item .item1 ul li a:hover {
    color: var(--bg-primary);
}
.Light-Theme .wrapper .left-item .item1 h4 {
    color: var(--bg-primary);
    font-weight: 600;
}
.Light-Theme .wrapper .left-item .item1.item04 ul li button {
    color: var(--bg-primary);
}
.Light-Theme .wrapper .left-item .item1 ul {
    border-color: rgba(24, 10, 33, 0.1);
}
.Light-Theme .wrapper .left-item .item1.item04 ul li a span.number {
    color: rgb(24 10 33 / 50%);
}
.Light-Theme .wrapper .left-item {
    background: #F4F4F6;
}
.Light-Theme .wrapper .left-item .item2 a,.Light-Theme .wrapper .left-item .item2 p {
    color: rgb(24 10 33 / 50%);
}
.Light-Theme .wrapper .left-item .item1 ul li a svg {
    width: 20px;
}
.Light-Theme .Notifications .top-noti h4 {
    color: #180A21;
}
.Light-Theme .Notifications h3 {
    color: rgba(24, 10, 33, 0.7);
    background: #F9F9FA;
}
.Light-Theme .Notifications .item .media-body h6 {
    color: #180A21;
}
.Light-Theme .Notifications .item .media-body p {
    color: rgba(24, 10, 33, 0.5);
}
.Light-Theme .Notifications .item .media-body p strong {
    color: #180A21;
}
.Light-Theme .Notifications .item .media-body small {
    color: rgba(24, 10, 33, 0.5);
}
.Light-Theme .Notifications .item {
    border-color: #E9E9ED;
}
.Light-Theme .Notifications .item-all a {
    color: #A768FF;
}
.Light-Theme .nav-drop .dropdown-menu, .Light-Theme .Notifications-body {
    background: #fff;
    border: 1.5px solid #E0DEF7;
    box-shadow: 0px -10px 10px rgb(16 10 85 / 10%);
}
.Light-Theme .Notifications::before {
    background: #fff;
}
.Light-Theme .home-banner {
    background: #A768FF;
}
.Light-Theme .mide-item .Top-Artists h4 {
    color: var(--bg-primary);
}
.Light-Theme .mide-item .Top-Artists .banner-item a .p {
    color: var(--bg-primary);
}
.Light-Theme .mide-item .top-songs h4 {
    color: var(--bg-primary);
}
.Light-Theme .mide-item .top-songs .slider .ex-a .title-item .songstitle {
    color: var(--bg-primary);
}
.Light-Theme .mide-item .top-songs .slider .ex-a .title-item .singer-name {
    color: var(--bg-primary);
    opacity: 0.8;
}
.Light-Theme .mide-item .top-songs .slider ul li a {
    color: var(--bg-primary);
    background: #E9E9ED;
}
.Light-Theme .Contribution h2 {
    color: var(--bg-primary);
}
.Light-Theme .Contribution .item-left h4 {
    color: rgb(24 10 33 / 50%);
    border-color: rgba(24, 10, 33, 0.1);
}
.Light-Theme .Contribution .item-left .top-item .media {
    background: #F4F4F6;
}
.Light-Theme .Contribution .item-left .top-item .media-body h4 {
    color: #180A21;
}
.Light-Theme .Contribution .item-left .top-item .media-body h4 span {
    color: #180A21;
}
.Light-Theme .Contribution .item-left .top-item .media-body p {
    color: #180A21;
}
.Light-Theme .Contribution .item-left .top-item .media-body .box-inner .media {
    background: #fff;
}
.Light-Theme .Contribution .item-left .top-item .media-body .box-inner .media .media-body h3 {
    color: #180A21;
}
.Light-Theme .Contribution .item-left .top-item .media-body .box-inner .media .media-body h3 span {
    color: #180A21;
}
.Light-Theme .Contribution .item-left .top-item .media-body .box-inner .media img{
    position: relative;
}
.Light-Theme .Contribution .item-left .top-item .media-body .box-inner .media img::before{
    position: absolute;
    content: "";
}
.Light-Theme .Contribution .item-right h3 {
    color: #180A21;
    border-color: rgba(24, 10, 33, 0.1);
}
.Light-Theme .Contribution .item-right .con-item .media .media-body h4 {
    color: #180A21;
}
.Light-Theme .Contribution .item-right .con-item .media .media-body p {
    color: rgb(24 10 33 / 50%);
}

.Light-Theme .wrapper .left-item .item2 p {
    opacity: 1; 
}
.Light-Theme .sidenav .closebtn {
    background: #f4f4f6;
}
.Light-Theme .Search-Results-for {
    color: rgba(24, 10, 33, 0.5);
}
.Light-Theme .Search-Results-for span {
    color: #180A21;
}
.Light-Theme .item-table .table>tbody>tr:nth-of-type(odd)>* {
    color: #180A21;
    background: #F9F9FA;
}
.Light-Theme .item-table table tbody tr td {
    color: #180A21;
}
.Light-Theme .table-hover>tbody>tr:hover>* {
    --bs-table-accent-bg: #e9e9e9;
}
.Light-Theme .item-table table tbody tr th span {
    color: #180A21;
}
.Light-Theme .item-table .pagination li:first-child a {
    color: rgba(24, 10, 33, 0.5);
    background: #E9E9ED;
}
.Light-Theme .item-table .pagination li a {
    color: var(--bg-tertiary);
}
.Light-Theme .item-table .pagination li:last-child a {
    background: #E9E9ED;
}
.Light-Theme .item-table .pagination li.active a, .Light-Theme  .item-table .pagination li:hover a {
    background: #A768FF;
    color: #fff;
}
.Light-Theme .profile-item .media-body h2 {
    color: var(--bg-tertiary);
}
.Light-Theme .profile-item .media-body p {
    color: rgba(24, 10, 33, 0.7);
    color: var(--bg-tertiary);
}
.Light-Theme .profile-item .media-body .button-item ul li a {
    background: #E9E9ED;
    color: var(--bg-tertiary);
}
.Light-Theme .mide-item .nav_body .nav-pills .nav-link.active, .Light-Theme .mide-item .nav_body .nav-pills .show>.nav-link {
    color: var(--bg-tertiary);
    border-color: var(--bg-tertiary);
}
.Light-Theme .mide-item .nav_body ul li button {
    color: var(--bg-tertiary);
}
.Light-Theme .mide-item .nav_body ul.nav.nav-pills.mb-3::before {
    background: #E9E9ED;
}
/* .Light-Theme .Played .table>:not(caption)>*>* {
    background: #F9F9FA;
    border-color: #F9F9FA;
} */
.Light-Theme .Played table tbody tr th {
    color: #180A21;
}
.Light-Theme .Played table tbody tr td:nth-child(2) span.chip {
    color: #180A21;
}
.Light-Theme .Played table tbody tr td, .Light-Theme .Played table thead tr th {
    color: hsl(277deg 53% 8% / 70%);
}
.Light-Theme .Performances {
    background: #F9F9FA;
}
.Light-Theme .Performances .media .media-body h4 strong {
    color: #180A21;
}
.Light-Theme .Performances .media .media-body h4 {
    color: rgba(24, 10, 33, 0.7);
}
.Light-Theme .Performances .media .media-body p {
    color: #180A21;
}
.Light-Theme .Performances .media .per-button button{
     color: #180A21;
}
.Light-Theme .Performances .video_play ul li a {
    color: #180A21;
}
.Light-Theme .arrow-show-hide-toggoler button svg {
    fill: #000;
}
.Light-Theme .mide-item .Top-Artists .banner-item a .number-item {
    border: 2px solid #ffffff;
}
.Light-Theme .nav-drop .edit-profile-drop.dropdown-menu[data-bs-popper] {
    top: 130%;
    right: 0;
    left: auto;
    margin-top: 0.125rem;

}
.Light-Theme .edit-profile-drop .Notifications::before {
    right: 5px;
    left: auto;
    top: -4px;
}
.Light-Theme .Notifications .item .media .box-img .hash-number {
    border: 2px solid #ffffff;
}
.Light-Theme .item-edit-profile-left.wrapper .left-item .item1 ul li a.active, .Light-Theme .item-edit-profile-left.wrapper .left-item .item1 ul li a:hover {
    background: #ffffff;
}
.Light-Theme .Edit-Profile .Edit-top h2 {
    color: #180A21;
}
.Light-Theme .Edit-Profile .Avatar .media {
    border-bottom: 1px solid rgba(24, 10, 33, 0.1);
}
.Light-Theme .Login .box form label {
    color: #180A21;
}

.Light-Theme .Login .box form input[type='number'],
.Light-Theme .Login .box form input[type='email'],
.Light-Theme .Login .box form input[type='name'],
.Light-Theme .Login .box form input[type='password'],
.Light-Theme .Login .box form select {
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: var(--bg-primary);
    background: #F4F4F6;
    border: 1px solid #D2D2DA;
}
.Light-Theme .form-check-input:checked {
    background-color: #A768FF !important;
    border-color: #A768FF !important;
}
.Light-Theme .form-check-input:checked[type=checkbox] {
    background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e) !important;
}
.Light-Theme .Login {
    padding: 0px;
}
.Light-Theme .Login .Login_heading {
    padding: 15px;
    background: #fff;
}
.Light-Theme .Login .box h1 {
    color: var(--bg-primary);
}
.Light-Theme .Login .box p {
    color: rgba(24, 10, 33, 0.7);
}
.Light-Theme .top-songs-body .filter .btn.dropdown-toggle {
    /* background: #E9E9ED; */
}
.Light-Theme .filter .dropdown-menu form .filter-item h4 {
    color: var(--bg-primary);
}
.Light-Theme .filter .dropdown-menu form h6 {
    color: var(--bg-primary);
}
.Light-Theme .filter .dropdown-menu form .form-check label {
    color: var(--bg-primary);
}
.Light-Theme .filter .dropdown-menu {
    background: #fff;
}
.Light-Theme .mide-item .profile-body ul li {
    margin-right: 30px;
}
.Light-Theme .profile-item .media .left-side ul li a {
    color: rgba(24, 10, 33, 0.7);
}
.Light-Theme .profile-item .media-body p.location sub {
    color: rgba(24, 10, 33, 0.5);
}
.Light-Theme .profile-item .media .box-img span {
    border: 2px solid #ffffff;
}
.Light-Theme .Played table tbody tr td .ex-a {
    background: #E9E9ED;
    color: #180A21;
}
.Light-Theme .Subscriptions .item a h4 {
    color: #180A21;
}
.Light-Theme .Subscriptions .item a p {
    color: #180A21;
}
.Light-Theme .Subscriptions .item button {
    background: #E9E9ED;
    color: #180A21;
}
.Light-Theme .Subscriptions .item {
    background: #F9F9FA;
}
.Light-Theme .slider .ex-a .title-item .songstitle {
    color: #180A21;
}
.Light-Theme .slider .ex-a .title-item .singer-name {
    color: #040106;
    opacity: 0.8;
}
.Light-Theme .Blinding-Lights .Blinding-right .media .media-body a.title {
    color: #180A21;
}
.Light-Theme .Blinding-Lights .Blinding-right .media .media-body p {
    color: #180A21;
}
.Light-Theme .Blinding-Lights .Blinding-right .media .media-body .Margo-Piano {
    color: #180A21;
}
.Light-Theme .Video-Tutorial form input {
    background: #F4F4F6;
    border: 2px solid var(--bg-tertiary);
    color: var(--bg-primary);
}
.Light-Theme .Video-Tutorial form input::placeholder {
    color: var(--bg-primary);
}
.Light-Theme .Video-Tutorial .add-comment {
    border-color: #F4F4F6;
}
.Light-Theme .Video-Tutorial .user-comment .media .user-body h4 a{
    color: #180A21;
}
.Light-Theme .Video-Tutorial .user-comment .media .user-body h4 span {
    color: #180A21;
}
.Light-Theme .Video-Tutorial .user-comment .media .user-body p {
    color: #180A21;
}
.Light-Theme .Video-Tutorial .user-comment {
    border-color: #F4F4F6;
}
.Light-Theme .Video-Tutorial .user-comment .media .user-body ul li a {
    color: #180A21;
}
.Light-Theme .Video-Tutorial .user-comment .media .user-body ul li button {
    color: #180A21;
}
.Light-Theme .Video-Tutorial .lock-right-item .Blinding-Lights .Blinding-right .media .media-body a.title {
    color: #180A21;
}
.Light-Theme .Video-Tutorial .lock-right-item .Blinding-Lights .Blinding-right .media .media-body p {
    color: #180A21;
}
.Light-Theme .mide-item .top-songs .slider a .piano-shadow {
    background: transparent;
}
.Light-Theme .slider .box-hover {
    padding: 0px;
    /* border: 1px solid #000; */
    box-shadow: 0px 0px 0px 1px #000;
    margin: 4px;
}

.Light-Theme .youtube_video_link .add-comment {
    background: #F9F9FA;
}
.Light-Theme .youtube_video_link .add-comment form .media-body .inner-button-item input {
    padding-left: 50px;
    background: #E9E9ED;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
}
.Light-Theme .add-comment form .comment-button button {
    background: var(--bg-primary);
    border-radius: 8px;
    padding: 8px 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: var(--text-primary);
    border: none;
}
.Light-Theme .titme-line-video {
    background: #F4F4F6;
    border-radius: 8px;
}
.Light-Theme .titme-line-video .accordion .accordion-item h2 button {
    color: #180A21;
}
.Light-Theme .titme-line-video .accordion .accordion-item .accordion-collapse .accordion-body .box-line .box-line-item ul li {
    background: #fff;
}
.Light-Theme .titme-line-video .accordion .accordion-item .accordion-collapse .accordion-body .box-line .box-line-item ul li h4 {
    color: #180A21;
}
.Light-Theme .titme-line-video .accordion .accordion-item .accordion-collapse .accordion-body .box-line .box-line-item ul li p {
    color: #180A21;
}
.Light-Theme .accordion-body .box-line .box-line-item ul li::after {
    background: #ffffff !important;
}
.Light-Theme .titme-line-video .accordion .accordion-item .accordion-collapse .accordion-body .box-line .line {
    background: #E9E9ED;
}
.Light-Theme .titme-line-video .accordion .accordion-item .accordion-collapse .accordion-body .box-line .box-line-item ul li::before {

    border: 2px solid #E9E9ED;
}
.Light-Theme .Edit-Profile .Avatar .privacy-settings form label h4 {
    color: #180A21;
}
.Light-Theme .Edit-Profile .Avatar .privacy-settings form label p {
    color: #180A21;
}
.Light-Theme .Edit-Profile .Avatar .privacy-settings form .form-switch {
    border-color: rgba(24, 10, 33, 0.1);
}
.Light-Theme .Login .box form .constom-check-line p {
    color: #180A21;
}
.Light-Theme .Login .box form .constom-check-line {
    border-color: rgba(24, 10, 33, 0.1);
}
.Light-Theme .Login .box form .constom-check-radio h4 {
    color: #180A21;
}
.Light-Theme .Login .box form .constom-check-radio {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 24px;
    border-color: rgba(24, 10, 33, 0.1);
}
.Light-Theme .slider .box-hover:hover {
    background: #A768FF;
    box-shadow: 0px 0px 0px 1px #000, 0 0 10px 6px #a768ff;
    /* box-shadow: 0 0 10px 6px #a768ff; */
}

.accordion-button:not(.collapsed) {
    box-shadow: none;
}

.youtube_video_link {
    width: 861px;
    max-width: 100%;
}
.youtube_video_link .add-comment {
    background: #22112C;
    border-radius: 8px;
    border: 0;
    padding: 24px 28px;
    margin-bottom: 24px;
}

.youtube_video_link .add-comment form .media-body .inner-button-item {
    position: relative;
}
.youtube_video_link .add-comment form .media-body .inner-button-item input {
    padding-left: 50px;
}
.youtube_video_link .add-comment form .media-body .inner-button-item label{
    position: absolute;
    top: 1px;
    left: 15px;
}
.youtube_video_link .add-comment form .media-body .inner-button-item label img {
    
    width: 28px;
}
.youtube_video_link .add-comment form .comment-button {
    padding-bottom: 0;
    margin-top: 10px;
}


.titme-line-video {
    background: #22112C;
    border-radius: 8px;
    padding: 16px;
    margin: 20px 0;
}
.titme-line-video .accordion{}
.titme-line-video .accordion .accordion-item {
    border: 0;
    background: transparent;
}
.titme-line-video .accordion .accordion-item h2{}
.titme-line-video .accordion .accordion-item h2 button {
    background: transparent;
    border: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    color: var(--text-primary);
}
.titme-line-video .accordion .accordion-item .accordion-collapse{}
.titme-line-video .accordion .accordion-item .accordion-collapse .accordion-body{}
.titme-line-video .accordion .accordion-item .accordion-collapse .accordion-body .box-line{}
.titme-line-video .accordion .accordion-item .accordion-collapse .accordion-body .box-line .line {
    background: #412952;
    border-radius: 24px;
    width: 100%;
    height: 4px;
}
.titme-line-video .accordion .accordion-item .accordion-collapse .accordion-body .box-line .box-line-item{}
.titme-line-video .accordion .accordion-item .accordion-collapse .accordion-body .box-line .box-line-item ul {
    padding-top: 16px;
    margin: 0;
}
.titme-line-video .accordion .accordion-item .accordion-collapse .accordion-body .box-line .box-line-item ul li:last-child{
    margin-right: 0;
}
.titme-line-video .accordion .accordion-item .accordion-collapse .accordion-body .box-line .box-line-item ul li::after {
    position: absolute;
    content: "";
    background: #412952;
    border-radius: 2px;
    width: 20px;
    height: 14px;
    top: -5px;
    left: 4px;
    right: 0;
    margin: auto;
    transform: rotate(45deg);
    z-index: 1;
}
.titme-line-video .accordion .accordion-item .accordion-collapse .accordion-body .box-line .box-line-item ul li::before {
    position: absolute;
    content: "";
    background: #C499FF;
    border: 2px solid #3E3B40;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    top: -25px;
    left: 0;
    right: 0;
    margin: auto;
}
.titme-line-video .accordion .accordion-item .accordion-collapse .accordion-body .box-line .box-line-item ul li {
    background: #412952;
    border-radius: 8px;
    padding: 8px;
    display: inline-block;
    text-align: left;
    width: auto;
    margin-right: 60px;
    position: relative;
    z-index: 99;
}
.titme-line-video .accordion .accordion-item .accordion-collapse .accordion-body .box-line .box-line-item ul li h4 {
    font-weight: 400;
    font-size: 12px;
    line-height: 135%;
    margin-bottom: 4px;
    color: var(--text-primary);
    opacity: 0.7;
    position: relative;
    z-index: 99;
}
.titme-line-video .accordion .accordion-item .accordion-collapse .accordion-body .box-line .box-line-item ul li p {
    font-weight: 500;
    font-size: 12px;
    line-height: 135%;
    color: var(--text-primary);
    margin-bottom: 0;
}
.titme-line-video .accordion .accordion-item .accordion-collapse .accordion-body .box-line .box-line-item ul li p img{
    margin-right: 5px;
}
.titme-line-video .accordion-button:not(.collapsed)::after {
    background-image: url("../img/arrow-top.svg");
    transform: rotate(0deg);
}

.titme-line-video .accordion-button::after {
    background-image: url("../img/arrow-top.svg");
    transform: rotate(-180deg);
}
.titme-line-video .accordion-button:focus {
    box-shadow: none;
}


.Login .box form .constom-check-line {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 24px !important;
    padding-bottom: 24px;
}
.Login .box form .constom-check-line .form-check{
    margin-bottom: 10px !important;

}
.Login .box form .constom-check-line .form-check input{}
.Login .box form .constom-check-line .form-check label {}
.Login .box form .constom-check-line p {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: var(--text-primary);
    margin-bottom: 0;
}
.Login .box form .constom-check-radio {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 24px;
}
.Login .box form  .constom-check-radio h4{
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    color: var(--text-primary);
}
.Login .box form .constom-check-line{}
.Login .box form .constom-check-line{}





.privacy-settings .form-check-input:checked {
    background-color: #A768FF !important;
    border-color: #A768FF !important;
}

.privacy-settings .form-check-reverse {
    padding-right: 1.5em;
    padding-left: 0;
    text-align: left;
}
.privacy-settings .form-check-reverse .form-check-input {
    float: right;
    margin-right: -1.5em;
    margin-left: 0;
    width: 4em;
    height: 1.9em;
}
.privacy-settings  .form-check-input:checked {
    background-color: #0d6efd;
    border-color: #0d6efd;
}

.Edit-Profile .Avatar .privacy-settings form{}
.Edit-Profile .Avatar .privacy-settings form .form-switch {
    padding-bottom: 24px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.Edit-Profile .Avatar .privacy-settings form input{}
.Edit-Profile .Avatar .privacy-settings form label{
    padding-right: 10px;
}
.Edit-Profile .Avatar .privacy-settings form label h4{
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    color: var(--text-primary);
    margin-bottom: 8px;
}
.Edit-Profile .Avatar .privacy-settings form label p{
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: var(--text-primary);
    margin-bottom: 0;
}


.Edit-Profile .Avatar form button.btn {
    background: white;
    border-radius: 8px;
    padding: 16px 48px;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: var(--bg-primary);
    border: none;
    width: 100%;
    text-align: center;
    margin-bottom: 32px;
}


.ck.ck-editor__main>.ck-editor__editable {
  background-color: rgb(24, 10, 33) !important;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: #2a2d33;
    border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.1);
}

.Light-Theme::-webkit-scrollbar-thumb, .Light-Theme ::-webkit-scrollbar-thumb {
    background: #d7d7db;
}

@media only screen and (max-width: 992px) {
    header {
        display: none;
    }
    .wrapper {
        padding-top: 0px;
    }
    .wrapper:has(.Homepage) {
        padding-top: 40px;
        overflow: hidden;
    }
    .wrapper:has(.Video-Tutorial, .profile-page) {
        overflow: hidden;
    }
    .wrapper:has(.search-song) {
        height: initial;
    }
    .Played table {
        max-width: unset;
    }

    @media (orientation: landscape) {
        .wrapper:has(.Video-Tutorial) {
            overflow: initial;
        }

        .Video-Tutorial .video-tutorial-left {
            margin: 0 -12px;
        }
    }
}

@media only screen and (min-width: 768px) {
    .mide-item .profile-body .nav {
        justify-content: center;
    }
}

@media screen and (pointer: coarse) {
    @supports (-webkit-backdrop-filter: blur(1px)) and (overscroll-behavior-y: none) and (overscroll-behavior-x: none)  {
        html {
            min-height: 100.3%;
            overscroll-behavior-y: none;
            overscroll-behavior-x: none;
        }
    }
}
@media screen and (pointer: coarse) {
    @supports (-webkit-backdrop-filter: blur(1px)) and (not (overscroll-behavior-y: none))  {
        html {
            height: 100%;
            overflow: hidden;
        }
        body {
            margin: 0px;
            max-height: 100%;
            overflow: auto;
            -webkit-overflow-scrolling: touch;
        }
    }
}