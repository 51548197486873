.playbackSpeedControl {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-top: 24px;
  margin-left: 5px;
  justify-content: flex-end;

  .speedTooltip {
    cursor: default;
  }

  .barsContainer {
    flex: 1;
    padding-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .playbackRateSelect {
    border-radius: 10px;
    padding: 3px;
    background-color: var(--bg-primary);
    color: white;
  }

  :not(:first-child) {
    align-self: flex-start;
  }
}

.tutorialsSections {
  position: relative;
  margin-top: 10px;
  white-space: nowrap;

  .section {
    position: relative;
    display: inline-block;
    height: 8px;
    overflow: visible;
    margin-right: 2px;
    
    .bar {
      background-color: #9B46D7;
      height: 100%;
      transition: scale 0.2s;
      cursor: pointer;
    }
    
    .tooltip {
      display: none;
      user-select: none;
      z-index: 1000;
      flex-direction: column;
      position: absolute;
      bottom: 230%;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
      background-color: var(--bg-primary);
      padding: 4px 10px;
      border-radius: 4px;
      box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.75);

      span {
        width: 100%;
        text-align: center;

        &:first-child {
          font-weight: 500;
        }
      }

      // &::after {
      //   content: '';
      //   z-index: -1;
      //   position: absolute;
      //   background-color: inherit;
      //   left: calc(50% - 6px);
      //   bottom: -5px;
      //   transform: translateX(-50%);
      //   width: 12px;
      //   height: 12px;
      //   transform: rotate(45deg);
      //   border-radius: 2px;
      // }
    }

    &:first-child .bar {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    &:nth-last-child(2) .bar {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }

    &:nth-child(odd) .bar {
      background-color: #7C84EF;
    }
  }

  .progressIndicator {
    position: absolute;
    top: 7px;
    width: 5px;
    height: 14px;
    background-color: whitesmoke;
    border-radius: 5px;
    z-index: 1;
    cursor: pointer;
  }

  &.lightMode {
    .tooltip {
      color: var(--text-primary);
    }

    .progressIndicator {
      background-color: var(--bg-tertiary);
    }
  }

  @media (orientation: landscape) and (max-width: 992px) {
    margin: 5px 5px 0 5px;
  }
}

.loopLabel {
  display: flex;
  width: fit-content;
  align-items: center;
  margin-top: 2px;
  //transition: all 0.2s ease-in-out;

  span {
    user-select: none;
  }

  img {
    width: 30px;
    cursor: pointer;
  }
}

@media (hover: hover) {
  .section:hover .tooltip {
    display: flex;
  }

  .section .bar:hover {
    scale: 1 1.2;
  }

  .progressIndicator:hover {
    transform: scale(1.2, 1.1);
  }
}