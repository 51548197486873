.song {
  H1, 
  H2 {
    margin-bottom: 0;
  }
}


.videoWrapper {
  position: relative;
  margin: 0 -12px;
  
  .responsiveYoutube {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
    min-width: 200px;
    min-width: 200px;

    &.paused {
        padding-top: 109.375%;
        margin-top: -30.5625%;
    }

    /* Then style the iframe to fit in the container div with full height and width */
    IFRAME {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
  }

  @media screen and (max-width: 992px) and (orientation: landscape) {
    margin: 0;
  }
}
