.landing {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1440' height='1084' fill='none'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath fill='%23171A1F' d='M0 0h1440v1084H0z'/%3E%3Cg filter='url(%23b)'%3E%3Ccircle cx='104' cy='77' r='154' fill='%23833FFF'/%3E%3C/g%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h1440v1084H0z'/%3E%3C/clipPath%3E%3Cfilter id='b' width='1908' height='1908' x='-850' y='-877' color-interpolation-filters='sRGB' filterUnits='userSpaceOnUse'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur result='effect1_foregroundBlur_69_35' stdDeviation='400'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    min-height: 100vh;

    margin: -100px -20px 0;
    padding: 100px 20px 0;

    width: 100%;

    H4 {
      font-size: 1.25rem;
      line-height: 1.75rem;
      font-weight: 400;

      color: rgb(34,211,238);
      text-transform: capitalize;

      margin-bottom: 15px;
    }

    H1 {
      line-height: 80px;
      font-size: 3.75rem;
      text-transform: capitalize;
      font-weight: 600;
      margin-bottom: 1.5rem;
    }
}

.inner {
    max-width: 1273px;
    margin: 0 auto;
}

.textBlock {
    text-align: left;
}

.exploreBtn {
  padding-top: 22px;
  padding-bottom: 22px;
  padding-left: 2.25rem;
  padding-right: 2.25rem;

  font-size: 1.25rem;
  line-height: 1.75rem;
  text-transform: capitalize;
  font-weight: 600;
  background-color: rgb(124,58,237);

  border-radius: 0.75rem;
  display: inline-block;

  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.cards {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  padding-bottom: 50px;
}

.cardWrapper {
  max-width: 270px;
  margin-top: 20px;
}

.artists {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 40px;

  padding-bottom: 50px;

  A {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;

    &:hover {
      text-decoration: none;
    }

    IMG {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }

    SPAN {
      line-height: 1;
      text-transform: capitalize;
      font-weight: 600;
      font-size: 12px;
    }
  }
}

.mobile {
  IMG {
    max-width: calc(min(100%, 350px));
    display: block;
    height: auto;
    margin: auto;
    border-radius: 68px;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.8) 0px 0px 67.9125px 0px;
  }
}