.viewers-holder {
  line-height: 18px;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
}
.viewers {
  display: flex;
  margin-top: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 135%;
  color: #FFFFFF;
  opacity: 0.7;
}