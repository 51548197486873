.slider {
  :global {
    .swiper {
      padding-bottom: 20px;
      padding-top: 4px;
    }
    .swiper-pagination {
      bottom: 0;
    }
    .swiper-pagination-bullet {
      background-color: #2C1C37;
      opacity: 1;
    }
    .swiper-pagination-bullet-active {
      background-color: rgb(0, 122, 255);
    }
  }
}
