.spinnerWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .spinnerText {
    font-weight: bold;
    text-align: center;
    margin-bottom: 15px;
  }

  .loader {
    position: relative;
    height: 40px;
    width: 6px;
    color: #FEFEFE;
    animation: paddles 0.75s ease-out infinite;
  }

  .loader:before {
    content: "";
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 15px;
    width: 12px;
    height: 12px;
    background: linear-gradient(45deg, #7C84EF, #9B46D7);
    border-radius: 50%;
    animation: ballbounce 0.6s ease-out infinite;
  }

  @keyframes paddles {
    0% {
      box-shadow: -25px -10px, 25px 10px
    }

    50% {
      box-shadow: -25px 8px, 25px -10px
    }

    100% {
      box-shadow: -25px -10px, 25px 10px
    }
  }

  @keyframes ballbounce {
    0% {
      transform: translateX(-20px) scale(1, 1.2)
    }

    25% {
      transform: scale(1.2, 1)
    }

    50% {
      transform: translateX(15px) scale(1, 1.2)
    }

    75% {
      transform: scale(1.2, 1)
    }

    100% {
      transform: translateX(-20px)
    }
  }

}