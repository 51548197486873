.currentListSection {
  button {
    display: flex;
    gap: 7px;
    align-items: center;
    width: max-content;
    white-space: nowrap;
    background-color: #A768FF;
    color: white;
    font-weight: 500;
    border-radius: 1rem;
    border: none;
    padding: 8px 12px;
    transition: all 0.3s;

    &:hover,
    &.activeList {
      background-color: white;
      color: var(--bg-primary);

      img {
        filter: invert(1);
      }
    }
  }

  .row:hover {
    filter: brightness(0.98);
  }
  
  .listPickerButtonContainer {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }

  .listPickerSwiper {
    padding: 0 20px;
    justify-content: center;

    h4 {
      font-size: 1.3rem;
    }
  }

  .currentListSection {
    margin-top: 50px;
  }

  .key {
    p {
      white-space: nowrap;
      margin: 0;

      @media (max-width: 992px) {
        & {
          margin-bottom: 5px;
        }
      }
    }
  }

  .diffAndViewsMobile {
    display: none;
  }

  .tempo,
  .releasedDate,
  .key {
    white-space: nowrap;
  }

  @media (max-width: 575.98px) {
    TABLE TBODY TR TH {
      max-width: 30px;
      font-size: 12px;
      padding-left: 8px;
      padding-right: 0;
      text-align: center;
    }
  }

  @media (max-width: 991.98px) {
    THEAD {
      display: none;
    }

    TD:nth-last-child(-n + 4):not(.diffAndViewsMobile) {
      display: none;
    }

    .songName {
      a {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }
      :global {
        .chip {
          margin-left: 0 !important;
        }
      }
    }

    .diffAndViewsMobile {
      display: flex !important;
      flex-direction: column;
      align-items: center;
      gap: 5px;
      padding-bottom: 20px;
      margin-right: 0;
    }

    .artist {
      display: none;
    }
  }

  :global {
    .table-img {
      width: 1%;
      min-width: 1%;

      IMG {
        min-width: 49px;
      }
    }
  }

  .youtubeThumbnail {
    // Minimum size for youtube thumbnails according to documentation
    min-width: 120px !important;
    min-height: 70px !important;
  }

  @media (min-width: 992px) {

    .filterMobile,
    .artistMobile,
    .tempoMobile,
    .channelNameMobile {
      display: none;
    }
  }

  .songName {
    text-align: left !important;
  }

  .views {
    min-width: 72px;
    white-space: nowrap;
  }

  .artistMobile,
  .channelNameMobile {
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    opacity: .5;

    & > a {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .channelNameMobile {
    font-weight: 500;
  }
}