.primbg {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1440' height='1084' fill='none'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath fill='%23171A1F' d='M0 0h1440v1084H0z'/%3E%3Cg filter='url(%23b)'%3E%3Ccircle cx='104' cy='77' r='154' fill='%23833FFF'/%3E%3C/g%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h1440v1084H0z'/%3E%3C/clipPath%3E%3Cfilter id='b' width='1908' height='1908' x='-850' y='-877' color-interpolation-filters='sRGB' filterUnits='userSpaceOnUse'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur result='effect1_foregroundBlur_69_35' stdDeviation='400'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");
    //background-color: red;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    min-height: 100vh;
    width: 100%;
    position: fixed;

    z-index:-1;
}

:global(.Light-Theme) .primbg {
    background-image: none;
    background-color: white;
}