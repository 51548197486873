.searchSong {
  margin-top: 90px;
  
  .loadMoreButtonWrapper {
    display: flex;
    justify-content: center;
    height: 100%;
    padding-top: 50px;

    .loadMoreButton {
      text-align: center;
      border: none;
      border-radius: 50%;
      width: 90px;
      height: 90px;
      color: white;
      font-weight: bold;
      background: var(--bg-gradient);
    }
  }
}
