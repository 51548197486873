.chordProgressionSwiper {
  width: 100%;
  position: relative;
  padding: 80px 0;
  margin-top: 20px;

  &.chordEdit {
    padding-top: 30px;
  }

  .chordProgressionSlide {
    display: flex;
    align-self: center;
    border: solid #8E8E8E 1px;
    border-radius: 5px;
    width: fit-content;
    
    .chordProgression {
      display: flex;
      border: solid #8E8E8E 1px;
      border-radius: 5px;
      justify-content: center;
      width: 100%;
      
      .chord {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 3.5rem;
        width: 4.5rem;
        font-weight: bold;
        font-size: 1.15rem;
        text-shadow: 2px 2px black;
        cursor: default;

        &.chordEdit {
          height: 3.375rem;
          width: 4.125rem;
          font-size: 1.2rem;
        }
        
        &:not(:last-child) {
          border-right: solid #2A2A2A 2px;
        }

        @media screen and (max-width: 768px) {
          height: 3.5rem;
          width: 4.5rem;
          font-size: 1.2rem;
        }

        @media screen and (max-width: 500px) {
          height: 2.5rem;
          width: 4rem;
          font-size: 1rem;
        }
      }
      @media screen and (max-width: 992px) {
        border: solid var(--bg-primary) 1px;
      }
    }
    @media screen and (max-width: 992px) {
      border: solid var(--bg-primary) 1px;
    }
  }

  .swiperNavPrev, .swiperNavNext {
    color: #8E8E8E;
    
    @media screen and (max-width: 335px) {
      transform: scale(0.8);
    }
  }

  @media screen and (max-width: 992px) and (orientation: landscape) {
    margin-top: 0;
    padding: 20px 0;
  }
}
