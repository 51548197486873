.difficultyWrapper {
  background-color: transparent;
  width: fit-content;
  border: solid 2px #FFFFFF4F;
  border-radius: 1rem;
  padding: 4px 10px;
  font-weight: 500;
  font-size: 0.85rem;
}

.lightMode {
  &.difficultyWrapper {
    border-color: rgba(0, 0, 0, 0.5);
  }
}
