
@media (max-width: 992px) {
  .rankedContainer {
    margin-left: -12px;
    margin-right: -12px;
  }
}

th, td {
  text-align: center;
}

.row {
  position: relative;
  TD {
    padding-top: calc(0.5rem + 8px);
    padding-bottom: calc(0.5rem + 8px);
  }
  &.activeVideo {
    TD {
      border-bottom: none;
      padding-bottom: 8px;
    }
  }
}

.row:hover {
  filter: brightness(0.98);
}

.row[data-request="show"]:hover .requestButtonContainer {
  display: inline-block;

  button {
    background-color: var(--bg-primary);
  }
}

.dkTableImg {
  min-width: 141px;
  IMG {
    width: auto!important;
  }
}

.infoBadge {
  background: hsla(0,0%,100%,.1);
  border-radius: 10px;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-right: 5px;
  margin-top: 0;
  margin-bottom: 8px;
  padding: 4px 16px;
  text-align: center;
  text-transform: capitalize;
  display: inline-block;

  SPAN {
    color: hsla(0,0%,100%,.5);
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    text-transform: capitalize;
  }
}

:global(.Light-Theme) {
  .infoBadge {
    background: rgba(242,242,242);
    color: rgb(23, 26, 31);

    SPAN {
      color: rgba(0, 0, 0, 0.7);
    }
  }
}

.requestButtonContainer {
  display: none;
  position: absolute;
  left: 0;
  width: 100%;
  height: 4.18rem;
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.1) 70%);
  border: none;
  
  button {
    height: 36px;
    align-items: center;
    padding: 5px 10px;
    border-radius: 8px;
    background-color: var(--bg-primary);
    border: 1px solid #ffffff1c;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
    color: var(--text-primary);
    font-size: 12px;
    font-weight: 600;
    margin-left: 5px;
  }
}

.optionsButton {
  background-color: transparent !important;
  border: none;
  border-radius: 50%;

  &:hover {
    background-color: transparent !important;
  }
}

.rank {
  padding-left: 0 !important;
  
  span {
    margin-left: 5px;
  }
}

.key p {
  white-space: nowrap;
  margin: 0;
  font-size: 14px;
}

.tempo, .releasedDate, .key, .views {
  white-space: nowrap;
}

.songName {
  text-align: left !important;

  a.songNameLink, .artistMobile {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
}

.views, .filterMobile, .channelNameMobile {
  display: none;
}

.viewsMobile {
  display: none;
}

.artistMobile, .channelNameMobile {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  opacity: .5;
}

.channelNameMobile {
  font-weight: 500;
}

:global {
  .table-img {
    width: 1%;
    min-width: 1%;
    
    IMG {
      min-width: 49px;
    }
  }
}

.youtubeThumbnail {
  // Minimum size for youtube thumbnails according to documentation
  min-width: 120px !important;
  min-height: 70px !important;
}

.requestedAt, .requestStatus {
  font-weight: 100 !important;
  color: white !important;
}

.lightMode {
  .artistMobile, .channelNameMobile {
    opacity: 1;
  }

  .rank {
    img {
      filter: invert(1);
    }
  }

  .viewsMobile {
    img {
      filter: invert(1);
    }
  }

  .requestedAt, .requestStatus {
    color: initial !important;
  }

  .requestButtonContainer {
    background: linear-gradient(270deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.0) 70%);
  }
}

.songImg {
  aspect-ratio: 16 / 9;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  max-width: 120px;
  min-width: 120px;
  min-height: 70px;
  cursor: pointer !important;
}

.cartResponsiveYoutube {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  min-height: 200px;
  min-width: 200px;

  transform: scale(1.005);

  /* Then style the iframe to fit in the container div with full height and width */
  IFRAME {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
  }
}

@media (max-width: 992px) {
  .key p {
    margin-bottom: 5px;
  }

  .viewsMobile {
    display: block;
  }

  .tempoMobile {
    display: block;
  }
}

@media (max-width: 575.98px) {
  TABLE TBODY TR TH {
    max-width: 30px;
    font-size: 12px;
    padding-left: 8px;
    padding-right: 0;
    text-align: center;
  }

  .requestButtonContainer {
    BUTTON {
      max-width: 80px;
      height: auto;
    }
  }
}

@media (max-width: 991.98px) {
  .requestButtonContainer {
    text-align: right;
  }
  THEAD {
    display: none;
  }

  TD:nth-last-child(-n + 3):not(.views, .requestStatus, .requestedAt) {
    display: none;
  }

  .songName :global .chip {
    margin-left: 0 !important;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .artist {
    display: none;  
  }

  .rank {
    span {
      margin: 0;
    }
  }
}

@media (min-width: 992px) {
  .views {
    display: table-cell;
  }

  .tempoMobile {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .songName {
    max-width: 144px;
  }
}