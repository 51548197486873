.sortButton {
  display: flex;
  background-color: transparent;
  border: none;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  color: white;
  font-size: 15px;
  transition: background-color 0.2s ease-in-out;
  
  img {
    margin-right: 0.5rem;
  }
  
  &:hover {
    background-color: var(--bg-secondary);
  }
  &:active {
    scale: 0.98;
  }
}

.stampsList {
  position: relative;

  LI {
    position: absolute !important;
    margin-right: -100px;
    transform: translate(-50%);
    cursor: pointer;
    z-index: 2;
  }

  H4,
  P {
    position: relative;
    z-index: 2;
  }

  :global(.btn-close) {
    width: 0.5em;
    height: 0.5em;
    background-size: 0.5em;
    background-color: white;
    margin: -3px -3px 0 7px;
    vertical-align: top;
  }

  // .deleteBtn {
  //   background: #180A21;
  //   margin-left: 10px;
  //   border: 0;
  //   font-weight: 600;
  //   font-size: 14px;
  //   line-height: 140%;
  //   color: #FFFFFF;

  //   SPAN {
  //     display:inline-block;
  //     //transform: rotate(45deg);
  //   }
  // }
}

.collapsedts {
  H4,
  IMG {
    display: none;
  }
}

.selectedts {
  z-index: 100 !important;
  &,
  &::after {
    background-color: #180a21 !important;
  }
}

.commentTag {
  margin-top: 20px;
  :global {
    BUTTON {
      color: white;
      background-color: rgb(67, 50, 75);
      min-width: 20px;
      font-size: 12px;
      font-weight: 600;

      &:not(:first-child) {
        margin-left: 4px;
      }

      &.active {
        background-color: rgb(34, 17, 44);
        border-color: white;
      }

      &:hover,
      &:focus {
        color: white;
        box-shadow: none;
      }
    }
  }
}
.commentWrapper {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-clip: padding-box;
  border: 1px solid rgba(255, 255, 255, 0.2);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.commentInput {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-clip: padding-box;
  border: none !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:focus {
    outline: none;
  }
}

.selectButton {
  text-align: left;
  width: 125px !important;

  &:hover {
    cursor: pointer;
  }
  &::after {
    position: absolute;
    right: 6px;
    top: 4px;
  }
}

.selectList {
  border-radius: 8px;
  background: #43324b;
  display: none;
  list-style: none;
  padding: 4px 0;
  margin-top: -4px !important;
}
.selectListShow {
  display: flex;
  flex-direction: column;
}
.selectListItem {
  padding: 6px 24px;
  width: 125px !important;
  border: 1px solid rgba(255, 255, 255, 0);
  &:hover {
    cursor: pointer;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
  }
}

.lightMode {
  .sortButton {
    border: 2px solid var(--bg-tertiary);
    background-color: transparent;
    color: var(--bg-primary);

    img {
      filter: invert(1);
    }
  }
}

@media screen and (min-width: 992px) {
  .commentsContainer {
    max-height: 50vh;
    overflow-y: auto;
  }
}
