.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 20px;

  .chordProgression {
    display: flex;
    border: solid #8E8E8E 1px;
    border-radius: 5px;
    justify-content: center;
    width: fit-content;

    .chord {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 1.1rem;
      padding: 2px 8px;

      &:not(:last-child) {
        border-right: solid #2A2A2A 2px;
      }
    }

    @media screen and (max-width: 992px) {
      border: solid var(--bg-primary) 1px;
    }
  }
}