.songCard {
  display: flex;
  position: relative;
  border: 2px solid #2a2d33;
  border-radius: 6px;
  flex-direction: column;
  width: fit-content;
  min-width: 265px;
  height: 100%;
  transition: all 0.2s;

  .songImg {
    aspect-ratio: 16 / 9;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    max-width: 100%;
    min-width: 120px;
    min-height: 70px;
    cursor: pointer !important;
  }

  .songInfo {
    padding: 5px 8px 8px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: #0D0F12;

    .songName {
      display: -webkit-box !important;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      font-weight: 600;
    }
  
    .artistName {
      font-size: 12px;
      opacity: 0.8;
    }

    .songStats {
      display: grid;
      margin: 12px 0;
      place-items: center;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 4px;

      div {
        width: 100%;
        text-align: center;
        padding: 4px;
      }

      p {
        margin: 0;
        font-size: 0.8rem;
        font-weight: bold;
        letter-spacing: 0.04rem;
        white-space: nowrap;
      }
      
      span {
        font-size: 0.75rem;
        font-weight: 100;
        letter-spacing: 0.05rem;
        white-space: nowrap;
      }

      .key {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        border-right: 1px solid rgba(255, 255, 255, 0.1);
        margin: 0 -5px -4px 0;
      }

      .uniqueChords {
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        border-left: 1px solid rgba(255, 255, 255, 0.1);
        margin: -4px 0 0 -5px;
      }
    }
  
    .footer {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 4px;
      background-color: var(--bg-primary);
      padding: 8px;
  
      .playButtonContainer {
        display: flex;
        height: 36px;
        align-items: center;
        padding: 5px 10px;
        border-radius: 8px;
        background-color: white;
        border: 1px solid #ffffff1c;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
        -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
        -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
        pointer-events: all;
        text-decoration: none;
        
        .playButton {
          width: 26px;
          height: 26px;
        }
        span {
          color: #1c1c1c;
          font-size: 12px;
          font-weight: 600;
          margin-left: 5px;
        }
        &:hover {
          background-color: rgb(188, 188, 188);
          // border: 1px solid #2d2d2d;
        }
      }
      .pfPicturesContainer {
        position: relative;
        display: flex;
        align-items: center;
        margin-right: 10px;
        
        .pfPicture {
          width: 26px;
          height: 26px;
          border-radius: 50%;
          margin-left: -7px;
          border: 2px solid #1C1C1C;
        }
      }
      .bookmarkButton {
        img {
          width: 32px;
        }
      }
    }
  }

  &:hover {
    border: 2px solid #a768ff;
    box-shadow: 0 0 2px 2px #a768ff;
  }

  &.lightMode {
    .songInfo {
      background-color: var(--text-primary);
    }

    .artistName {
      opacity: 1;
    }

    .footer {
      background-color: #F9F9FA;

      .playButtonContainer {
        box-shadow: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        background-color: var(--bg-tertiary);

        span {
          color: white;
        }

        &:hover {
          background-color: #171a1fe4;
        }
      }

      img {
        filter: invert(1);
      }
    }
  }
}

.cardVideoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;
  background-color: #0e0e0e;
  flex: 1;
}

.cartResponsiveYoutube {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  min-height: 200px;
  min-width: 200px;

  transform: scale(1.005);

  /* Then style the iframe to fit in the container div with full height and width */
  IFRAME {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
  }
}