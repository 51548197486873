.tutorialPage {
  padding: 0 12px;
  margin-bottom: 75px;

  h2, h3 {
    margin-bottom: 0;
  }
  
  .sectionPickerButtonContainer {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 30px;

    button {
      display: flex;
      gap: 7px;
      align-items: center;
      width: max-content;
      white-space: nowrap;
      background-color: rgba(255, 255, 255, 0.1);
      color: var(--text-primary);
      font-weight: bold;
      border-radius: 8px;
      border: 2px solid transparent;
      padding: 8px 12px;
      transition: all 0.3s;
  
      &:hover, &.activeSection {
        background-color: white;
        color: var(--bg-primary);
  
        img {
          filter: invert(1);
        }
      }
    }
  }
  .sectionContainer {
    border: 2px solid rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    padding: 12px;

    H1 {
      font-size: 16px;
      opacity: 0.8;
    }
  }
  .songStats {
    display: grid;
    margin: 12px 0;
    place-items: center;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 4px;

    div {
      width: 100%;
      text-align: center;
      padding: 4px;
    }

    p {
      margin: 0;
      font-size: 0.8rem;
      font-weight: bold;
      letter-spacing: 0.04rem;
      white-space: nowrap;
    }
    
    span {
      font-size: 0.75rem;
      font-weight: 100;
      letter-spacing: 0.05rem;
      white-space: nowrap;
    }

    .key {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      border-right: 1px solid rgba(255, 255, 255, 0.1);
      margin: 0 -5px -4px 0;
    }

    .uniqueChords {
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      border-left: 1px solid rgba(255, 255, 255, 0.1);
      margin: -4px 0 0 -5px;
    }
  }
  .floatingButtonsContainer {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 150px;
    right: 8px;
    flex-direction: column;
    gap: 14px;
    z-index: 2;
    
    .floatingButton {
      border: none;
      width: 34px;
      height: 34px;
      background-color: transparent;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      transition: transform 0.05s;
      
      &:active {
        transform: scale(0.9);
      }
    }
    .tutorialInfoButton {
      background-image: url("../../../public/icons/treble.svg");
    }
    .listButton {
      background-image: url("../../../public/icons/list.svg");
    }
    .bookmarkButton {
      padding: 0;
      
      img {
        width: 100%;
      }
    }
    .commentsButton {
      width: 32px;
      height: 32px;
      background-image: url("../../../public/icons/comments.svg");
      background-size: cover;
    }
  }
  .popoutMenu {
    position: fixed;
    left: 0;
    height: calc(100dvh - 150px);
    width: 100%;
    z-index: 3;
    transition: all 0.3s;
    background-color: rgba(20, 20, 20, 0.9);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    padding: 6px 8px;
    overflow-y: auto;
    overflow-x: hidden;
    border-top: solid 1px #242424;
    
    .menuHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 8px;
      margin-bottom: 8px;
      border-bottom: solid 2px rgba(128, 128, 128, 0.308);
      
      .menuLabel {
        font-weight: bold;
        margin-left: 24px;
        flex: 1;
        text-align: center;
        opacity: 0.8;
      }
      .closeMenuButton {
        border: none;
        width: 24px;
        height: 24px;
        margin: 2px 2px 0 0;
        background: url("../../../public/icons/cross.svg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transition: transform 0.1s;
        
        &:active {
          transform: scale(0.9);
        }
      }
    }
  }

  &.lightMode {
    .sectionContainer {
      border-color: rgba(0, 0, 0, 0.2);
    }
    
    .sectionPickerButtonContainer {
      button {
        background-color: transparent;
        border-color: var(--bg-tertiary);
        color: var(--bg-primary);
        
        &:hover, &.activeSection {
          background-color: var(--bg-tertiary);
          color: var(--text-primary);
        }
      }
    }

    .popoutMenu {
      background-color: rgba(255, 255, 255, 0.9);
    }

    .closeMenuButton {
      filter: invert(1);
    }

    .playbackRateSelect {
      background-color: transparent;
      color: initial;
      border: 2px solid var(--bg-tertiary);
    }
  }
  
  @media screen and (max-width: 992px) {
    background: linear-gradient(rgb(23 26 31 / 90%), rgb(23 26 31 / 80%)) !important;
    height: calc(100vh - 50px);
    overflow: hidden;

    &.lightMode {
      background: linear-gradient(rgb(255 255 255 / 90%), rgb(132 134 137 / 80%)) !important;
    }

    @media (orientation: landscape) {
      height: 100%;
      overflow: initial;
      overflow-x: hidden;
    }
  }
}
.tutorialHeader {
  display: flex;
  background-color: transparent;
  gap: 15px;
  align-items: center;
  padding: 10px 0;

  img {
    width: 90px;
  }
  .tutorialInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
    line-height: 18px;
    font-size: 0.9rem;
    text-shadow: 0 0 5px var(--bg-tertiary);

    .songName {
      font-weight: bold;
    }
    .artistName {
      opacity: 0.7;
    }
    .tutorialViewsNDifficulty {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.videoWrapper {
  position: relative;
  margin: 0 -12px;
  
  .responsiveYoutube {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */

    &.paused {
        padding-top: 109.375%;
        margin-top: -30.5625%;
    }

    /* Then style the iframe to fit in the container div with full height and width */
    IFRAME {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
  }

  @media screen and (max-width: 992px) and (orientation: landscape) {
    margin: 0;
  }
}

.backgroundShadow {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  transition: 0.5s all;
}

.playbackRates {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  margin-top: 20px;
  :global {
    BUTTON {
      color: white;
      background-color: rgb(67, 50, 75);
      width: 60px;
      text-shadow: 0 0 5px var(--bg-tertiary);

      &.active {
        background-color: rgb(34, 17, 44);
        border-color: white;
      }

      &:hover, 
      &:focus {
        color: white;
        box-shadow: none;
      }
    }
  }
  @media screen and (max-width: 991px) {
    justify-content: center;
  }
}

.mainName {
  margin-top: 20px;
}

.tutorialButtonsContainer {
  position: sticky;
  bottom: 10px;
  float: right;
  z-index: 10;
  
  button {
    background-color: #A768FF;
    border: none;
    border-radius: 50%;
    padding: 15px;
    transition: scale 0.1s;
    animation: fadein 0.2s;
  }

  img {
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  
  .primary {
    img {
      width: 35px;
      height: 35px;
    }
  }

  .secondary {
    position: absolute;
    padding: 8px;
    z-index: -1;

    img {
      width: 30px;
      height: 30px;
    }

    &.bookmarkedDesktopButton {
      bottom: 73px;
      left: 9px;
      animation: popout-top cubic-bezier(0.68, -0.55, 0.27, 1.55) 0.35s;
    }
    
    &.learnedDesktopButton {
      left: -55px;
      bottom: 8px;
      animation: popout-left cubic-bezier(0.68, -0.55, 0.27, 1.55) 0.35s;
    }
  }

  .tooltip {
    position: absolute;
    z-index: 999;
    white-space: nowrap;
    background-color: #A768FF;
    padding: 4px 10px;
    border-radius: 10px;
    user-select: none;
    animation: fadein 0.2s;
  
    &::before {
      content: '';
      z-index: -1;
      position: absolute;
      right: -4px;
      top: 9px;
      background-color: inherit;
      width: 12px;
      height: 12px;
      transform: rotate(45deg);
      border-radius: 2px;
    }

    &.bookmarkedTooltip {
      left: -149px;
      top: -46px;
    }

    &.learnedTooltip {
      left: -199px;
      top: 19px;
    }
  }
  
  @keyframes fadein {
    from {
      opacity: 0;
      transform: scale(0.8);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes popout-top {
    from {
      bottom: 9px;
      left: 9px;
      opacity: 0;
    }
    to {
      bottom: 73px;
      left: 9px;
      opacity: 1;
    }
  }
  
  @keyframes popout-left {
    from {
      left: 10px;
      bottom: 8px;
      opacity: 0;
    }
    to {
      left: -55px;
      bottom: 8px;
      opacity: 1;
    }
  }

  @media (max-width: 991px) {
    display: none;
  }
}