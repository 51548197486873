.detectChordProgsWrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 22px 0;
  
  .noChordProgs {
    width: 60%;
    text-align: center;
    font-style: oblique;
  }
}
