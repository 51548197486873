.tutorialHeaderWrapper {
  padding: 5px 0;
  margin: 0 -5px;
  z-index: 100;

  & > H1 {
    font-size: 16px;
    opacity: 0.8;
  }
}

.tutorialHeader {
  display: flex;
  background-color: transparent;
  gap: 15px;
  align-items: center;
  
  img {
    width: 90px;
  }
}

.tutorialInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  line-height: 18px;
  font-size: 0.9rem;

  .songName {
    font-weight: bold;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.9rem;
    margin-bottom: 0;
    line-height: 18px;
  }
  .artistName {
    opacity: 0.7;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 0;
  }
  .tutorialViewsNDifficulty {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.hovButtons {
  display: flex;
  gap: 10px;
  justify-content: left;
  align-items: center;
  margin-top: 8px;

  button {
    display: flex;
    gap: 7px;
    align-items: center;
    width: max-content;
    white-space: nowrap;
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
    font-weight: 500;
    border-radius: 8px;
    border: none;
    padding: 8px 12px;
    transition: all 0.3s;

    &:disabled {
      opacity: 0.5;
    }

    //&:hover:not(:disabled), 
    &.activeList {
      color: var(--bg-primary);

      img {
        filter: invert(1);
      }
    }

    // &:hover:not(:disabled) {
    //   background-color: rgba(255,255,255,0.7);
    // }
    &.activeList {
      background-color: white;
    }
  }
}
