.container {
  text-align: center;
  margin-bottom: 13px;

  LI {
    display: inline-block;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    opacity: .5;
    margin-right: 5px;

    A {
      text-decoration-line: underline;
    }
  }

  display: none;
  @media screen and (max-width: 992px) {
    display: block;
  }
}

:global(.Light-Theme) .copyright SPAN {
  color: rgb(23, 26, 31);
}