.rerollBtn {
  width: fit-content;
  height: 50px;
  color: white;
  background-color: var(--bg-primary);
  font-weight: 600;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  margin: 2px;

  &:active {
    border-color: whitesmoke;
  }

  img {
    border-radius: 0 !important;
    width: 25px !important;
    height: 100% !important;
    margin-right: 6px !important;
  }
}