.songName {
  font-size: 16px;
  font-weight: 600;
  margin: 0 !important;
}

.artistName {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  opacity: 0.6;
}

.creatorName {
  font-size: 14px;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.tutorialContainer {
  flex-wrap: wrap;
}

.separator {
  width: 100%;
  opacity: 0.1;
}

@media screen and (min-width: 992px) {
  .relatedTutorials {
    max-height: 60vh;
    overflow: hidden auto;
  }
}