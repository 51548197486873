.homePage {
  .homePageBgContainer {
    display: none;
    position: absolute;
    z-index: -1000;
    top: 0;
    left: 0;
    filter: blur(34px);
    background:linear-gradient(180deg, transparent, var(--bg-primary));
    
    .homePageBg {
      width: 99.6vw;
      height: 240px;
      opacity: 0.5;
    }
  }
  
  .tabButtons {
    display: flex;
    align-items: center;
    justify-content: center;
    
    @media (max-width: 992px) {
      display: none;
      // & {
      //   position: fixed;
      //   width: fit-content;
      //   top: 90px;
      //   left: 50%;
      //   transform: translateX(-50%);
      //   z-index: 1000;
        
      //   span {
      //     text-shadow: 0 0 5px var(--bg-primary);
      //   }
      //   button {
      //     text-shadow: 0 0 7px var(--bg-primary);
      //     padding: 5px 10px;
      //   }
      // }
    }
  }
  .separator {
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0) 100%);
    height: 2px;

    @media (max-width: 992px) {
      & {
        display: none;
      }
    }
  }
  .noSubsMessage {
    font-size: 1.1rem;
    font-style: oblique;
    text-align: center;
    margin: 30px 0 100px;
  }
  .listPicker {
    margin-top: 10px;
    text-align: center;

    .hovButtons {
      button {
        display: flex;
        gap: 7px;
        align-items: center;
        width: max-content;
        white-space: nowrap;
        background-color: rgba(255, 255, 255, 0.1);
        color: white;
        font-weight: 500;
        border-radius: 8px;
        border: none;
        padding: 8px 12px;
        transition: all 0.3s;
  
        &:hover, 
        &.activeList {
          color: var(--bg-primary);
  
          img {
            filter: invert(1);
          }
        }
  
        &:hover {
          background-color: rgba(255,255,255,0.7);
        }
        &.activeList {
          background-color: white;
        }
      }
    }
    

    .listPickerButtonContainer {
      display: flex;
      gap: 10px;
      justify-content: center;
      align-items: center;
    }

    .listPickerSwiper {
      padding: 0 20px;
      justify-content: center;

      h4 {
        font-size: 1.3rem;
      }
    }
    .currentListSection {
      margin-top: 20px;
    }
  }

  &.lightMode {
    .tabButtons {
      span, button {
        text-shadow: none;
      }
    }

    .sectionSlide {
      .plistName {
        background-color: rgba(150, 150, 150, 0.1);
      }
    }

    .listPicker {
      button {
        background-color: transparent;
        border: 2px solid var(--bg-tertiary);
        color: var(--bg-primary);

        &:hover, &.activeList {
          background-color: var(--bg-tertiary);
          color: var(--text-primary);
          border: 2px solid var(--bg-tertiary);
        }
      }
    }

    .separator {
      background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 100%);
    }
  }
  
  @media (max-width: 992px) {
    & {
      margin: 0 -12px 20px -12px;
    }
    &.lightMode {
      .homePageBgContainer {
        display: none;
      }
    }
  }
}

.slidesContainer {
  .sectionSlide {
    .plistName {
      display: flex;
      align-items: center;
      gap: 6px;
      background-color: rgba(255,255,255,0.1);
      border-radius: 8px;
      width: fit-content;
      padding: 5px 10px;
      font-size: 1.3rem;
      margin-bottom: 10px;
      font-weight: bold;

      white-space: nowrap;
    }
    
    @media (max-width: 992px) {
      & {
        scroll-snap-align: center;
        padding: 20px 0;
        scroll-snap-stop: always;

        .plistName {
          margin-left: 10px;
        }
      }
    }
  }

  @media (max-width: 992px) {
    & {
      height: 100vh;
      scroll-snap-type: y mandatory;
      overflow-y: scroll;
      overflow-x: hidden;
      padding: 70px 0 200px 0;
    }
  }
}

.genresSwiper {
  min-height: 40px;
  margin: 0 -12px 0 -8px;
  .genresSwiperInner {
    transition: all 0.3s;
  }
}

.genresSwiper[data-scrolled] {
  .genresSwiperInner {
    position: fixed;
    //width: calc(100% - 96px);
    width: calc(100% - 74px);
    transition: all 0.3s;
    background: rgba(23, 26, 31, 0.95);
    padding-top:44px!important;
    padding-bottom: 12px!important;
    top:46px;
    z-index: 2;
    //padding-right:100px;;
  }
}

:global(.Light-Theme) {
  .genresSwiper[data-scrolled] .genresSwiperInner {
    background: white;
  }
}

@media (max-width: 992px) {
  .genresSwiper {
    margin: 0;
    .genresSwiperInner {
      width: 100%;
      top: 120px;
    }
  }

  .genresSwiper[data-scrolled] {
    .genresSwiperInner {
      width: 100%;
      top:42px;
    }
  }

  :global(.Light-Theme) {
    .genresSwiper[data-scrolled] .genresSwiperInner {
      background-color: rgb(227, 227, 231);
    }
  }
}