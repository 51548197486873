.mobileHomeHeader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: #171a1fb0;
  width: 100%;
  backdrop-filter: blur(40px);

  .searchBarContainer {
    display: flex;
    justify-content: center;

    .searchBar {
      width: 100%;
      margin: 20px 0 20px 10px;
  
      & form {
        width: 100%;
        
        .searchBarButton {
          margin-right: 5px;
        }
        
        & input[type=search] {
          width: 100%;
          font-size: 16px;
        }
      }
    }
  }
}