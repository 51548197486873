.songStats {
  display: grid;
  margin: 12px 0;
  place-items: center;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;

  h2, h3 {
    width: 100%;
    text-align: center;
    padding: 4px;
    line-height: 24px;
    margin-bottom: 0;
  }

  .crow {
    margin: 0;
    font-size: 0.75rem;
    font-weight: 400;
    letter-spacing: 0.04rem;
    white-space: nowrap;
    line-height: 18px;
  }
  
  .cspan {
    font-size: 0.9rem;
    font-weight: bold;
    letter-spacing: 0.05rem;
    white-space: nowrap;
  }

  .sharpCount {
    position: relative;
    border-radius: 16px;
    background-color: white;
    color: black;
    padding: 3px;
    font-weight: 600;
    font-size: 10px;
    cursor: help;
    border: 1px solid var(--bg-primary);
    line-height: 15px;

    .tooltip {
      position: absolute;
      white-space: nowrap;
      background-color: #A768FF;
      padding: 3px 6px;
      border-radius: 10px;
      transform: translateX(10%);
      user-select: none;
      animation: fadein 0.2s;
      color: white;

      span:not(:first-child) {
        border-top: 2px solid rgba(255, 255, 255, 0.3);
      }
  
      &::after {
        content: '';
        z-index: -1;
        position: absolute;
        background-color: inherit;
        left: -3px;
        top: 6px;
        width: 12px;
        height: 12px;
        transform: rotate(45deg);
        border-radius: 2px;
      }
    }
  }

  .key {

    .cdiv {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 25px;
      margin: 0 auto;
      
      button {
        background-color: transparent;
        border: none;
      }
    }
  }

  .tempo {
    border-bottom: 1px dashed rgba(255, 255, 255, 0.1);
    border-left: 1px dashed rgba(255, 255, 255, 0.1);
    margin: 0 0 -8px -1px;
  }

  .releaseDate {
    border-top: 1px dashed rgba(255, 255, 255, 0.1);
    border-right: 1px dashed rgba(255, 255, 255, 0.1);
    margin: -7px -1px 0 0;
  }

  .hoverUniqueChords {
    text-decoration: underline;
    cursor: pointer;
    user-select: none;
  }

  &.lightMode {
    .tempo, .releaseDate {
      border-color: rgba(0, 0, 0, 0.2);
    }
  }
}
