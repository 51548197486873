.switchListCarousel {
  display: flex;
  align-items: center;
  gap: 4px;
  
  label {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  
    span {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--bg-tertiary);
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 20px;
      border: 1px solid #ccc;
  
      &:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 3px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 50%;
      }
    }
  
    input:checked + span:before {
      -webkit-transform: translateX(25px);
      -ms-transform: translateX(25px);
      transform: translateX(25px);
    }
  }
  @media (max-width: 992px) {
    & {
      transform: scale(0.85);
    }
  }
}
