.topSongs {
  .title {
    margin-top: 20px;
    text-align: center;
  }
  
  .filter {
    margin-top: -46px;
  }

  .views {
    min-width: 80px;
    text-align: left!important;
  }

  @media (max-width: 575.98px) {
    TABLE TBODY TR TH {
      max-width: 30px;
      font-size: 12px;
      padding-left: 8px;
      padding-right: 0;
      text-align: center;
    }
    .views {
      text-align: left!important;
      max-width: 50px;
      min-width: 50px;
      text-align: center!important;
    }
  }

  @media (max-width: 991.98px) {
      THEAD {
        display: none;
      }

      TD:last-child {
        display: none;
      }

      .songName {
        :global {
          .chip {
            margin-left: 0!important;
          }
        }
      }
      
      .artist {
        display: none;  
      }
  }

  :global {
    .table-img {
      width: 1%;
      min-width: 1%;
      IMG {
        min-width: 49px;
      }
    }
  }

  @media (min-width: 992px) {
    .artistMobile {
      display: none;  
    }
  }

  .songName {
    text-align: left!important;
  }

  .artistMobile {
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    opacity: .5;
  }
}
