.mobileSideMenu {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  transition: all 0.5s;
  background-color: #242424c5;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);

  .sideMenuList {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;

    li {
      span {
        font-size: 0.85rem;
        opacity: 0.8;
      }
    }
  }

  .songmodeLogo {
    width: 50%;
    max-width: 180px;
  }

  hr {
    margin: 0 0 20px 0;
    width: 100%;
  }

  .closeMenuButton {
    position: absolute;
    right: 5px;
    top: 10px;
    border: none;
    width: 24px;
    height: 24px;
    margin: 2px 2px 0 0;
    background: url("../../../public/icons/cross.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    transition: transform 0.1s;

    &:active {
      transform: scale(0.9);
    }
  }
}