.pfpBackground {
  width: 100vw;
  height: 85px;
  margin: 0 -12px;
  margin-top: -30px;
}

.pfp {
  border: 5px solid var(--bg-primary);
  margin-top: -50px;
}

.userStats {
  display: flex;
  flex-direction: column;

  a {
    font-size: 16px !important;
  }

  img {
    width: 24px;
  }
  
  span {
    margin-left: 5px;
  }
}

.statsTabs {
  flex-wrap: nowrap;
  overflow-x: auto;
  justify-content: space-between;
  margin-bottom: 0 !important;
  border-bottom: 2px solid #212227;

  &::-webkit-scrollbar {
    display: none;
  }
}

.modeSwitchContainer {
  display: flex;
  justify-content: flex-end;
}

.editButton {
  display: flex;
  position: absolute;
  align-items: center;
  gap: 5px;
  right: 5px;
  z-index: 1;
  margin-top: 5px;
  font-style: oblique;

  span {
    opacity: 0.8;
    font-size: 16px;

    @media (max-width: 992px) {
      font-size: 14px;
    }  
  }

  img {
    width: 25px;
  }

  &:active {
    transform: scale(0.9);
  }
}

.sideMenuButton {
  position: absolute;
  top: 60px;
  left: 5px;
  background-color: var(--bg-primary);
  border-radius: 50%;
  border: none;
  width: fit-content;
  z-index: 1;

  img {
    width: 35px;
    height: 35px;
  }

  &:active {
    transform: scale(0.9);
  }

  @media (min-width: 575px) {
    & {
      left: initial;
      right: 5px;
      top: 5px;
      padding: 4px;
    }
  }
}

.tabContent {
  max-height: calc(100vh - 342px);
  overflow-y: auto;

  @media screen and (min-width: 576px) {
    max-height: calc(100vh - 390px);
  }

  @media screen and (min-width: 768px) {
    max-height: calc(100vh - 464px);
  }
}

.playedTab {
  margin: 12px auto 0;
  font-size: 12px;

  .playedItem {
    display: flex;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #2b2b2b;
    padding: 8px 0;

    .playedImg {
      padding-right: 2px;
      width: 150px;
    }

    .playedInfo {
      display: flex;
      flex-direction: column;

      .playedCreatorPfp {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 5px;
      }
      
      .playedSongName {
        font-weight: bold;
        font-size: 14px;
        padding-right: 2px;
        display: -webkit-box !important;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .playedArtistName {
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
        opacity: 0.6;
      }

      .viewersNDifficulty {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }

    &:last-child {
      border-bottom: none;
    }
  }

}

.requestedSongsTable {
  margin: 0 auto;
  margin-top: 10px;
  font-size: 12px;
  
  .requestedImg {
    width: 50px;
  }

  .songNames {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-self: center;
    line-height: 1rem;
    gap: 2px;

    .reqSongName {
      font-weight: bold;
    }

    .reqSongArtists {
      color: var(--text-secondary);
      opacity: 0.7;
    }
  }

  .reqDate, .reqStatus, .songNames {
    padding-right: 10px;
  }

  thead {
    font-style: oblique;
  }

  td {
    padding: 4px;
  }
}

.subscribedTabs {
  text-align: center;
  padding: 0;

  .subscribedTab {
    padding: 10px 20px;
  }
}

.subscribedWrapper {
  display: flex;
  overflow: auto;
  margin: 0 -10px;
  scroll-snap-type: x mandatory;
  &::-webkit-scrollbar {
    display: none;
  }

  .subscribedElement {
    scroll-snap-align: center;
  }
}

.searchFilterContainer {
  margin-top: 20px;
  text-align: center;
}

.sectionPickerButtonContainer {
  display: flex;
  gap: 10px;
  margin-top: 5px;
  align-items: center;
  justify-content: center;

  button {
    display: flex;
    gap: 7px;
    align-items: center;
    width: max-content;
    white-space: nowrap;
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
    font-weight: 500;
    border-radius: 1rem;
    border: none;
    padding: 8px 12px;
    transition: all 0.3s;

    &:hover, &.activeSection {
      background-color: white;
      color: var(--bg-primary);

      img {
        filter: invert(1);
      }
    }
  }
}

@media (min-width: 715px) {
  .requestedSongsTable, .playedTable {
    font-size: 14px;
  }
}

@media (min-width: 992px) {
  .requestedSongsTable, .playedTable {
    font-size: 16px;
  }

  .modeSwitchContainer {
    display: none;
  }

  .boxImg, .mediaBody {
    display: inline-block
  }

  .mediaBody {
    margin-top: 10px;
  }
}
