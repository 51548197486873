.modal {
    :global {
        .myNavButton {
        box-shadow:inset 0px 1px 0px 0px #ffffff;
        background:linear-gradient(to bottom, #f9f9f9 5%, #e9e9e9 100%);
        border-radius: 50px 0px 0px 50px;
        border:none;
        display:inline-block;
        cursor:pointer;
        color:#666666;
        font-family: 'Heebo', sans-serif;
        font-size:15px;
        font-weight:bold;
        padding:6px 24px;
        text-decoration:none;
        text-shadow:0px 1px 0px #ffffff;
        }
        .myNavButton:hover {
        background:linear-gradient(to bottom, #e9e9e9 5%, #f9f9f9 100%);
        background-color:#e9e9e9;
        }
        .myNavButton:active {
        position:relative;
        top:1px;
        }


        h1 {
        font-weight: 900;
        text-align: center;
        }

        h3 {
        font-weight: 300;
        }




        .container:hover .flap::after {
        transform: translatex(300px);
        }

        .container:hover .flap::before{
        transform: translatex(-300px);
        }

        .login {
        width: 325px;
        padding: 30px;
        padding-top: 0px;
        text-align: center;
        }
        .login__field {
        padding: 20px 0px;	
        position: relative;	
        }

        .login__icon {
        position: absolute;
        top: 30px;
        color: #1cadfe;
        }

        .login__input {
        border: none;
        border-bottom: 2px solid #D1D1D4;
        background: none;
        padding: 10px 18px;
        padding-left: 24px;
        font-weight: 700;
        width: 90%;
        transition: .2s;
        }

        .login__input:active,
        .login__input:focus,
        .login__input:hover {
        outline: none;
        border-bottom-color: #6A679E;
        }

        .login__submit {
        background: #fff;
        font-size: 16px;
        /* margin-top: 13px; */
        padding: 10px 14px;
        border-radius: 26px;
        border: 1px solid #D4D3E8;
        text-transform: uppercase;
        font-weight: 700;
        /* display: flex; */
        align-items: center;
        width: 94%;
        margin: auto;
        color: #1cadfe;
        /* box-shadow: 0px 2px 2px #1cadfe; */
        cursor: pointer;
        transition: .2s;
        text-align: center;
        margin-top: 1rem;
        }

        .login__submit:active,
        .login__submit:focus,
        .login__submit:hover {
        border-color: #1cadfe;
        outline: none;
        }

        .button__icon {
        font-size: 24px;
        margin-left: auto;
        color: #1cadfe;
        }
        .fa {
        /* padding: 5px; */
        font-size: 15px;
        width: 25px;
        text-align: center;
        text-decoration: none;
        margin: 5px 2px;
        border-radius: 50%;
        }

        .fa:hover {
        opacity: 0.7;
        }


        p {
        margin-top: 0;
        margin-bottom: 0.5rem;
        font-size: 1rem;
        }
        #msg {
        font-size: 14px;
        /* line-height: 300%; */
        padding-left: 14px;
        color: red;
        }
        #coredevslogo {
        width: 52%;
        }
        .findusdiv{
        position: fixed;
        margin-left: 64%;
        }

        .login__field {
        padding: 20px 0px;
        position: relative;
        padding-bottom: 12px;
        }
        .fa-eye:before {
        content: "\f06e";
        margin-left: -1rem;
        }

        .modal-content {
            line-height: 1.5715;
            width: auto;
            background-color: rgb(39, 20, 51);

            .modal-header {
                .btn-close {
                    background-color:white;
                }
            }
    
            .modal-body {
                padding-top: 0;
            }
        }
    }
}

.artistItem {
    text-align: left;
}

DIV[class$='-ValueContainer'] {
    .artistItem {
        IMG {
            display: none;
        }
    }
}

.pwaInstallModal {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    left: 0;
    bottom: 55px;
    width: 100vw;
    background-color: var(--bg-primary);
    border-radius: 8px;
    border: 2px solid rgba(220, 220, 220, 0.3);
    padding: 20px 30px 20px 20px;
    gap: 10px;

    .logo {
        width: 50px;
        height: 50px;
    }

    .message {
        flex-grow: 1;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        line-height: 1.5rem;
    }

    .closeButton {
        position: absolute;
        top: 4px;
        right: 4px;
        border: none;
        width: 18px;
        height: 18px;
        margin: 2px 2px 0 0;
        background: url("../../../public/icons/cross.svg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transition: transform 0.1s;
        
        &:active {
            transform: scale(0.9);
        }
    }

    .optionsIcon {
        width: 30px;
        height: 30px;
        margin: 0 3px;
    }

    .installButton {
        color: var(--bg-between-gradient);
        background: none;
        border: none;
        font-weight: bold;
    }

    @media (display-mode: standalone) {
        display: none;
    }
}