.level-stars {
  margin-bottom: 13px;

  ul li{
    display: inline-block;
    margin-right: 5px!important;
    vertical-align: baseline;

    img{
      width: 12px;
    }

    &:last-child{
      margin-right: 0;
    }
  }
}
