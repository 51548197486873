.plistTooltip {
  position: absolute;
  white-space: nowrap;
  background-color: #A768FF;
  color: var(--text-primary);
  padding: 3px 5px;
  border-radius: 10px;
  left: 56px;
  top: -3px;
  user-select: none;
  animation: fadein 0.2s;

  &::after {
    content: '';
    z-index: -1;
    position: absolute;
    background-color: inherit;
    left: -4px;
    top: 9px;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    border-radius: 2px;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}