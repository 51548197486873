.header {
    text-align: center;
    margin-top: 5px;
    text-transform: capitalize;
}

.artistletter {
    margin-top: 12px;
    DIV {
        padding-bottom: 12px;
    }
    LABEL, A {
        display: block;
    }
}

.banner {
    position: relative; 

    padding-top: 70px;

    margin-bottom: 30px;

    .bannerImage {
        width: 100%;
        height: 100%;
    
        background-size: cover;
    
        background-position: 0% 20%;
    
        filter: contrast(.8);

        position: absolute;
        top: 0;
        z-index: 1;
    }

    .bannerInner {
        margin-bottom: 0;
        color: white;
        font-size: 42;
        position: relative;
        width: 100%;
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 40%);
        z-index: 2;

        padding: 30px 20px 20px;

        H3 {
            margin: 10px 0 0;
            color: white;
            font-size: 56px;

            padding-top: 20px;
        }

        P {
            margin: 0;
            padding: 5px 0 20px;
        }
    }

    A {
        color: white;
        &:hover {
            color: white;
            text-decoration: underline;
        }
    }
}

.searchBar {
    text-align: center;
    margin: 15px 0 5px 0;
}

.creatorName {
    margin-top: 3px;
}