.dropdown{
  position: absolute;
  min-width: 180px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.dropdown li{
  z-index: 2;
  margin: 6px;
  margin-left: 0px;
  margin-right: 0px;
  padding: 8px;
  border-radius: 8px;
  margin: 5px;
  cursor: pointer;
}

.userStats {
  margin-left: auto;
  border-radius: 8px;
  padding: 4px;
  transition: none;

  span {
    font-size: 15px;
    opacity: 0.8;
  }
}

.lightMode {
  border-left: solid 0.2em transparent;
  &:hover {
    background-color: #F4F4F6 !important;
    border-left-color: #ef476f !important;
  }
  a{
    &:hover {
      color: #180A21 !important;
    }
  }
}

.darkMode {
  border-left: solid 0.2em transparent;
  &:hover {
    background-color: var(--bg-tertiary) !important;
    border-left-color: white !important;
  }

  a {
    &:hover {
      color: #FFFFFF !important;
    }
  }
}





