.allArtists {

  width: 80vw;
  margin-left: auto;
  margin-right: auto;

  H4 {
    text-align: center;
    margin-top: 5px;
    text-transform: capitalize;
  }

  H5, LI {
    font-size: 13px;
    font-weight: 400;
    line-height: 19px;
    margin: 4px 0;
  }

  H5 {
    color: hsla(0,0%,100%,.6);
    margin-top: 8px;
  }
}

.letters {

  A {
    background: hsla(0,0%,100%,.1);
    border-radius: 10px;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-right: 5px;
    margin-top: 0;
    margin-bottom: 8px;
    padding: 4px 12px;
    text-align: center;
    text-transform: capitalize;
    display: inline-block;

    &:global(.active),
    &:hover {
      background: hsla(0,0%,100%,.8);
      color: rgba(0,0,0,1.0);
      text-decoration: none;
    }
  }
}

:global(.Light-Theme) {
  .letters A {
    background: rgba(242,242,242);
    color: rgb(23, 26, 31);
  }
}


